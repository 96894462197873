/* eslint-disable consistent-return */

import { useEffect } from "react";

import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { AuthStore } from "@packages/store/models/AuthStore";

// import { ENTRANCE_TEST_KEY } from "constants/constants";
import { ROUTES } from "router/constants";

export const useEntranceTestCheck = (auth: AuthStore | undefined) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const { isSingIn = false, setEntranceTestId } = auth ?? {};

    const testId = searchParams.get("knowledge_test");

    if (testId && setEntranceTestId) {
      setEntranceTestId(testId);
      // localStorage.setItem(ENTRANCE_TEST_KEY, testId);

      searchParams.delete("knowledge_test");
      setSearchParams(searchParams);

      if (!isSingIn) {
        navigate(ROUTES.SIGN_UP_PAGE);
      }
    }
    // Нужно вызвать эффект только после инициализации auth стора
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
};
