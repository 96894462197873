export const toStringOrNull = (value: number | string | undefined | null) => {
  return value ? value.toString() : null;
};

export const toNumberOrNull = (value: any) => {
  const res = Number(value);

  if (Number.isNaN(res)) {
    return null;
  }

  return res;
};
