import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { utils, writeFile } from "xlsx-js-style";

import { useStores } from "@packages/store";
import { useCancelableFetch } from "@packages/store/hooks";
import { ClassGroup } from "@packages/store/models/ClassGroup/ClassGroup";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

import { DownloadButton } from "./styledComponents";
import { formatGroups } from "../../helpers";
import { DateRange } from "../GroupFilter";
import { Loading } from "../Loading";

export interface GroupDownloadProps {
  data: LearningGroup[];
  range: DateRange;
}

export const GroupsWithoutPriceDownload = ({
  data: entryData,
  range,
}: GroupDownloadProps): JSX.Element | null => {
  const { t } = useTranslation();

  const isGroupsEmpty = entryData.length === 0;

  const {
    api,
    auth: { user },
  } = useStores();
  const { isDistributor, isCoordinator } = user ?? {};
  const { fetch: fetchGroupRates, loading: isGroupRatesLoading } =
    useCancelableFetch();

  const formattedData = useMemo(() => {
    const filtered = entryData.filter((learningGroup) => {
      const hasPrices = learningGroup.classGroups.some(
        ({ rates }: ClassGroup) => {
          const actualRates = rates
            .filter((rate) => {
              return rate.amount > 0;
            })
            .filter((rate) => {
              const { startDate, endDate } = range;
              if (rate.deletedAt) {
                if (endDate && rate.deletedAt <= endDate) {
                  return false;
                }
                if (startDate && rate.deletedAt <= startDate) {
                  return false;
                }
              }
              if (!rate.activeFrom) {
                return true;
              }
              if (endDate) {
                return rate.activeFrom <= endDate;
              }
              return true;
            });
          return actualRates.length > 0;
        }
      );
      return !hasPrices;
    });
    return formatGroups(filtered).map((item) => ({
      [t("GroupList:group")]: item.group,
      [t("GroupList:teachers")]: item.teachers
        .map((teacher) => teacher.fullName)
        .join(", "),
      [t("GroupList:period")]: item.dates,
      [t("GroupList:lessonsCount")]: item.lessonsCount,
      [t("GroupList:total")]: item.total,
      [t("GroupList:finished")]: item.finished,
      [t("GroupList:left")]: item.left,
    }));
  }, [entryData, range, t]);

  const download = () => {
    const sheet = utils.json_to_sheet(formattedData);
    const book = utils.book_new();
    utils.book_append_sheet(book, sheet, t("GroupDownload:Groups") ?? "");
    writeFile(book, `${t("GroupDownload:Groups")}.xls`);
  };

  const buttonEnabled = useMemo(() => {
    return !isGroupRatesLoading && formattedData.length;
  }, [isGroupRatesLoading, formattedData.length]);

  useEffect(() => {
    if (isGroupsEmpty || !entryData.length) {
      return;
    }
    const ids = entryData.flatMap(({ classGroups }) =>
      classGroups.map(({ id }) => id)
    );
    fetchGroupRates((token) => {
      return api.getClassGroupRates({ ids }, token);
    });
  }, [api, fetchGroupRates, isGroupsEmpty, entryData]);

  if (!isDistributor && !isCoordinator) {
    return null;
  }

  return (
    <Loading loading={isGroupRatesLoading}>
      <DownloadButton onClick={download} disabled={!buttonEnabled}>
        {t("GroupDownload:WithoutPrice")}
      </DownloadButton>
    </Loading>
  );
};
