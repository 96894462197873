import {
  ExerciseResult,
  ExerciseResultStatus,
  ExerciseResultType,
} from "@packages/store/models/ExerciseResult/ExerciseResult";

import { ButtonType } from "./types";
import { IconType } from "../../../Icon";

export function getButtonType(result: ExerciseResult): ButtonType {
  if (result.hasErrors) {
    return "error";
  }
  if (result.status === ExerciseResultStatus.CHECKED) {
    return "success";
  }
  return "checking";
}

export function getTypeIcon(type: ExerciseResultType): IconType {
  const icons: Record<ExerciseResultType, IconType> = {
    [ExerciseResultType.SPEAKING]: "task-micro",
    [ExerciseResultType.WRITING]: "task-book",
  };

  return icons[type];
}
