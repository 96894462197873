const es = {
  Exercise: {
    Units: "Secciones",
    Blocks: "Bloques",
    Exercises: "Ejercicios",
    Unit: "Sección",
    Block: "Bloque",
    Exercise: "Ejercicio",
    Script: "Guion para el video",
    HideScript: "Ocultar guion",
    ShowScript: "Mostrar guion",
    LanguageSamples: "Muestras de idioma",
    Extras: "Materiales adicionales",
    ToolReload: "Actualizar",
    ToolTranslate: "Traducir",
    ToolCheck: "Verificar",
    ToolNext: "Siguiente",
    IsCheckedWarning: "La tarea ya ha sido verificada",
    NotFilledWarning: "No has respondido todas las preguntas",
    Translate: "Traducción",
    Vocabulary: "Vocabulario",
    NoTranslation: "No hay traducción",
    AddToDictionary: "Agregar al diccionario",
    SelectedTranslationsWarning: "Debes seleccionar de 1 a 3 traducciones",
    TranslateWordFailed: "No se pudo traducir la palabra",
    AddWordsFailed: "No se pudieron agregar las palabras",
    AddWordsSuccess: "Las palabras se han agregado con éxito",
    AddedBefore: "Se agregó anteriormente",
    EnterText: "Ingrese texto",
    Characters: "Caracteres",
    Words: "Palabras",
    TextSize: "Tamaño de tu texto:",
    WordsAtMinimum: "Palabras mínimo",
    LearnWords: "Aprender palabras",
    Ok: "Ok",
    Expand: "Expandir",
    Collapse: "Colapsar",
    Close: "Cerrar",
    ResizeTooltip: "Estirar para leer",
    ResetChangesTooltip: "Restablecer todos los cambios",
    NoChange: "Sin cambios",
    CorrectAnswer: "Respuesta correcta",
    DevTooltip:
      "Este tipo de ejercicio está en desarrollo, para volver a la interfaz anterior",
    FollowLink: "Sigue el enlace",
    CreativeExercisePassed: "Tu ejercicio ha sido enviado para revisión",
    CreativeExerciseVerified: "Tu trabajo ha sido revisado. Haga clic",
    CreativeExerciseVerifiedLink: "aquí",
    DialogExerciseConfirmAnswer: "Confirmar",
    DialogExerciseResetAnswer: "Sobrescribir",
    DialogExerciseSelectAnswer: "Este",
    DialogExerciseHelperLegend: "Cómo realizar el ejercicio",
    DialogExerciseFinishHelperLegend: "¡Felicidades!",
    DialogExerciseFirstHelper:
      "Mentalmente selecciona tu respuesta de la lista de mensajes a continuación, presiona el micrófono y pronúnciala.",
    DialogExerciseSecondHelper:
      "¡Excelente, el sistema reconoció tu voz! Confirma tu respuesta o sobrescríbela.",
    DialogExerciseThirdHelper:
      "Se han agotado tus intentos de entrada por voz, selecciona una de las opciones de respuesta manualmente.",
    DialogExerciseFinishHelper:
      "Has completado el ejercicio. Puedes escuchar todo el diálogo o pasar a la siguiente tarea haciendo clic en el botón de verificación.",
    DialogExerciseRecognitionError:
      "Lamentablemente, el sistema no pudo reconocer tu respuesta, intenta nuevamente.",
    AudioRecorderText: "Presiona para grabar ({{count}}) seg",
    RadioLineHelperTextLeft:
      "No se han indicado todas las respuestas correctas. Encuentra más",
    RadioLineHelperTextRight: "respuestas correctas.",
    NextDisabledWarning: "Verifica el ejercicio",
    BrokenError:
      "Este ejercicio está en desarrollo, realízalo en la interfaz anterior.",
    BrokenErrorLink: "Enlace",
  },
  ResultsModals: {
    UnitTitle: "Resultados de la sección {{name}}",
    BlockTitle: "Resultados del bloque {{name}}",
    Passed: "Correcto: {{count}}",
    Wrong: "Errores: {{count}}",
    Skipped: "Omitido: {{count}}",
    Blocks: "Bloques",
    Block: "Bloque {{order}}: {{name}}",
    Exercises: "Ejercicios",
    Exercise: "Ejercicio {{order}}: {{name}}",
    Repeat: "Repetir",
    Continue: "Continuar",
    DontShowAgain:
      "No volver a mostrar esta ventana (puedes habilitarla nuevamente en la configuración del perfil)",
    TestUnitTitle:
      "¡Felicidades! Has completado la etapa {{order}} de la prueba",
    TestUnitResultTitle: "Tu resultado -",
    TestUnit: "Sección {{order}}. {{name}}",
    TestUnitTotalProgress: "Progreso total",
    TestUnitResults: "Resultados",
    TestUnitNext: "Comenzar la siguiente etapa",
    TestUnitScoredPoints_zero: "{{count}}/{{total}} puntos",
    TestUnitScoredPoints_one: "{{count}}/{{total}} punto",
    TestUnitScoredPoints_few: "{{count}}/{{total}} puntos",
    TestUnitScoredPoints_many: "{{count}}/{{total}} puntos",

    TestCourseTitle: "¡Felicidades! Has completado la prueba",
    TestCourseRecomendedLevel: "Te recomendamos un curso de nivel -",
    TestCourseTotalProgress: "Progreso total",
    TestCourseResultProgress: "Resultados",
    TestCourseShowResults: "Ver resultados",
    TestCourseShowHourse: "Cantidad de horas",
    TestCourseHint:
      "Enfoque en el desarrollo de todas las habilidades lingüísticas (conversación, comprensión auditiva, lectura y escritura)",
    TestCourseA1Long:
      "Entiendo el significado principal de la conversación. Puedo describir a mis amigos y familia.",
    TestCourseA2Long:
      "Entiendo oraciones separadas y puedo hablar sobre temas cotidianos.",
    TestCourseB1Long:
      "Puedo comunicarme en la mayoría de las situaciones usando todos los tiempos verbales.",
    TestCourseB2Long:
      "Puedo defender mi punto de vista, negociar y escribir correos.",
    TestCourseC1Long:
      "Leo literatura con fluidez y puedo hacer presentaciones.",
    TestCourseC2Long:
      "Me comunico sobre cualquier tema a un nivel cercano al nativo.",
    TestCourseA1Short: "principiante",
    TestCourseA2Short: "elemental",
    TestCourseB1Short: "intermedio",
    TestCourseB2Short: "superior",
    TestCourseC1Short: "avanzado",
    TestCourseC2Short: "con perfección",
  },
  TestingCourseResult: {
    ModalTitle: "Resultados de la prueba",
    Confirm: "Ir a los resultados",
    ModalBodyEntrance:
      "Estimado {{fullName}}, se te recomienda un curso de nivel según la escala CEFR: {{cefrLevel}}.",
    ModalBodyFinal:
      'Estimado {{fullName}}, has completado la prueba, obteniendo $t(TestingCourseResult:courseMark, {"count": {{courseMark}} }) de {{maxMark}}.\nTu resultado es - {{percentage}}%.',
    ModalBodyDefault:
      "Estimado {{fullName}}, has completado la prueba con éxito.",
    courseMark_zero: "{{count}} puntos",
    courseMark_one: "{{count}} punto",
    courseMark_few: "{{count}} puntos",
    courseMark_many: "{{count}} puntos",
  },
};

export default es;
