import { Box } from "@mui/material";

import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";
import {
  PivotReportStatistics,
  PivotReport,
} from "@packages/store/services/Api";

import { AttendancePivotTable } from "../../components/AttendancePivotTable";
import {
  filterLessonsByDateRange,
  getFilteredStudentsByName,
} from "../../helpers";
import { DateRange } from "../../types";
import { AttendanceReportsGroupStatistics } from "../AttendanceReportsGroupStatistics";

export interface AttendanceReportsPivotTableProps {
  group?: LearningGroup;
  studentFilter?: string;
  dateRangeFilter?: DateRange;
  teacherId?: string;
}

export const AttendanceReportsPivotTable = ({
  group,
  studentFilter,
  dateRangeFilter,
  teacherId,
}: AttendanceReportsPivotTableProps): JSX.Element | null => {
  const { pivotReport = {}, statistics = {} } = group ?? {};

  const { scheduleTimes, students, lessons } = pivotReport as PivotReport;
  const { totalHours } = statistics as PivotReportStatistics;

  const getFilteredLessons = (items: Lesson[]) => {
    return filterLessonsByDateRange(items, dateRangeFilter);
  };

  const getFilteredLessonsToEnd = (items: Lesson[]) => {
    const { endDate } = dateRangeFilter ?? {};
    return filterLessonsByDateRange(items, { endDate });
  };

  const getCompletedLessons = (items: Lesson[]) => {
    return items.filter((lesson: Lesson) => lesson.isCompleted);
  };

  const filteredStudents = getFilteredStudentsByName(students, studentFilter);
  const filteredLessons = getFilteredLessons(getCompletedLessons(lessons));
  const completedLessons = getFilteredLessonsToEnd(
    getCompletedLessons(lessons)
  );
  // В статистику фильтровать по преподу не нужно, а в таблицу нужно
  const teacherLessons = filteredLessons.filter((lesson) =>
    teacherId ? lesson.teacherId === teacherId : true
  );

  const lessonsToTable = teacherLessons.sort((first, second) => {
    const firstDay = first.day?.getTime() ?? 0;
    const secondDay = second.day?.getTime() ?? 0;
    return firstDay < secondDay ? -1 : 1;
  });

  if (!lessonsToTable.length || !filteredStudents.length) return null;

  return (
    <>
      <Box mt="2rem">
        <AttendanceReportsGroupStatistics
          group={group}
          schedules={scheduleTimes}
          totalHours={totalHours}
          completedLessons={completedLessons}
          filteredLessons={filteredLessons}
        />
      </Box>
      <Box mt="2rem">
        <AttendancePivotTable
          students={filteredStudents}
          lessons={lessonsToTable}
        />
      </Box>
    </>
  );
};
