import { useCallback } from "react";

import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

import {
  AttendanceReports as PackageAttendanceReports,
  Filters,
} from "@packages/attendance";
import { buildSearchParams } from "@packages/shared/utils/searchParams";

import { ROUTES } from "router/constants";

export const AttendanceReports = observer((): JSX.Element => {
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const syncSearchParams = useCallback(
    (data: Filters) => {
      if (pathname !== ROUTES.ATTENDANCE_REPORT) return;

      const params = buildSearchParams(data);

      if (params.toString() !== searchParams.toString()) {
        setSearchParams(params);
      }
    },
    [pathname, searchParams, setSearchParams]
  );

  return (
    <PackageAttendanceReports
      searchParams={searchParams}
      syncSearchParams={syncSearchParams}
    />
  );
});
