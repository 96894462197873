import { useLayoutEffect } from "react";

import { Stack, Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Modal, ModalProps } from "components/shared/Modal";
import { TrimInput } from "components/TrimInput";
import { surchargeTypes } from "constants/constants";
import { usePrevious } from "hooks";
import { StyledButton } from "pages/TeacherDetails/styledComponents";

import {
  ActiveFromInput,
  AmountInput,
  ClassTypeSelect,
} from "../../../TeacherDetails/components/FormFields";

export interface ModalPayrollGroupProps extends Omit<ModalProps, "children"> {
  onSubmit: (data: ModalPayrollGroupFormValues) => void;
  group: LearningGroup | undefined;
}

export interface ModalPayrollGroupFormValues {
  surcharge: { id?: string; type: string; amount: string; activeFrom: Date }[];
}

export const ModalPayrollGroup = observer(
  ({ onSubmit, onClose, group, open, ...other }: ModalPayrollGroupProps) => {
    const { t } = useTranslation();

    const { teacherRate, surcharge: surchargeStore } = useStores();

    const {
      control,
      formState: { isSubmitting },
      register,
      handleSubmit,
      clearErrors,
      setValue,
    } = useForm<ModalPayrollGroupFormValues>();

    const { fields, append, remove } = useFieldArray({
      control,
      name: "surcharge",
    });

    const previousOpen = usePrevious(open);

    const handleAddSurcharge = () => {
      append({} as any);
    };

    const handleRemoveRate = (index: number) => () => {
      remove(index);
    };

    const handleClose: typeof onClose = (...args) => {
      onClose?.(...args);

      clearErrors();
    };

    useLayoutEffect(() => {
      if (!group) return;

      // На каждое открытие модалки нужно обновлять список доплат которые должны отрендерися
      if (open && !previousOpen) {
        const surcharges = surchargeStore.getByGroupId(group.id);

        if (!surcharges.length) {
          fields.forEach((_, i) => remove(i));
          return;
        }

        setValue(
          "surcharge",
          surcharges.map((s) => ({
            id: s.id,
            activeFrom: s.activeFrom!,
            type: s.type!,
            amount: s.amount!,
          }))
        );
      }
    }, [fields, group, open, previousOpen, remove, setValue, surchargeStore]);

    const teacher = group?.currentTeacher;
    const startDate = teacher?.includedAt
      ? format(teacher.includedAt, "dd.MM.yyyy")
      : "-";
    const rate = teacher && teacherRate.getLastByGroupId(group.id);

    const listSurchargeTypes = surchargeTypes.map(({ label }) => ({
      value: label,
      label: t(`Payroll:${label}`),
    }));

    return (
      <Modal
        open={open}
        onClose={handleClose}
        title={t("Payroll:ModalGroupTitle")}
        containerProps={{ type: "small" }}
        {...other}
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, minmax(0, 1fr))"
          rowGap="1rem"
          columnGap="0.5rem"
          mb="1rem"
          mt="2rem"
        >
          <TrimInput
            variant="outlined"
            label={t("Payroll:ModalGroupTeacher")}
            value={teacher?.fullName || "-"}
            disabled
          />
          <TrimInput
            variant="outlined"
            label={t("Payroll:ModalGroupName")}
            value={group?.name || "-"}
            disabled
          />
          <TrimInput
            variant="outlined"
            label={t("Payroll:ModalGroupStartDate")}
            value={startDate}
            disabled
          />
          <TrimInput
            variant="outlined"
            label={t("Payroll:ModalGroupRate")}
            value={rate?.amount ?? "-"}
            disabled
          />
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h2" color="custom.grey.main">
            {t("Payroll:ModalSurchargesTitle")}
          </Typography>

          <Stack spacing="1rem" mb="1rem">
            {fields.length ? (
              fields.map((field, index) => (
                <Box
                  key={field.id}
                  display="grid"
                  gridTemplateColumns="5fr 5fr 5fr 1fr"
                  gap="1rem"
                  alignItems="flex-end"
                >
                  <input
                    style={{ display: "none" }}
                    {...register(`surcharge.${index}.id` as const)}
                  />

                  <ClassTypeSelect
                    control={control}
                    name={`surcharge.${index}.type` as const}
                    data={listSurchargeTypes}
                    placeholder={t("Payroll:ModalSurchargeType")}
                    required={t("TeacherDetails:RequiredFieldError")}
                  />

                  <AmountInput
                    control={control}
                    name={`surcharge.${index}.amount` as const}
                    placeholder={t("Payroll:ModalSurchargeAmount")}
                    required={t("TeacherDetails:RequiredFieldError")}
                  />

                  <ActiveFromInput
                    control={control}
                    name={`surcharge.${index}.activeFrom` as const}
                    placeholder={t("Payroll:ModalSurchargeActiveFrom")}
                    required={t("TeacherDetails:RequiredFieldError")}
                  />

                  <Box flexGrow="0" alignSelf="flex-start" pt="1rem">
                    <Button variant="iconBtn" onClick={handleRemoveRate(index)}>
                      <Icon type="close" width={24} height={24} />
                    </Button>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body2" textAlign="center">
                {t("Payroll:ModalNoSurcharges")}
              </Typography>
            )}

            <StyledButton
              variant="add"
              sx={{ alignSelf: "flex-start" }}
              onClick={handleAddSurcharge}
            >
              <Icon type="bookAdd" sizeSquareIcon={16} />
              {t("Payroll:ModalSurchargesAdd")}
            </StyledButton>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" spacing="0.5rem">
            <Button
              variant="outlined"
              onClick={() => handleClose?.({}, "backdropClick")}
            >
              {t("Payroll:ModalGroupCancel")}
            </Button>
            <Button
              variant="primary"
              type="submit"
              sx={{ borderRadius: "4px" }}
              disabled={isSubmitting}
            >
              {t("Payroll:ModalGroupSubmit")}
            </Button>
          </Stack>
        </form>
      </Modal>
    );
  }
);
