import { Tooltip, Typography } from "@mui/material";

import { ContainerIcon } from "./styledComponents/ContainerIcon";
import { ContainerText } from "./styledComponents/ContainerText";
import { Icon } from "../Icon";

export interface TooltipProps {
  type: "icon" | "default";
  tooltipText: string;
  children: JSX.Element;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

type tooltip = {
  [key in TooltipProps["type"]]: () => JSX.Element;
};

export const TooltipCustom = ({
  type,
  children,
  tooltipText,
  placement,
}: TooltipProps): JSX.Element => {
  const tooltip: tooltip = {
    default: () => (
      <Tooltip
        title={
          <ContainerText>
            <Typography variant="tooltip">{tooltipText}</Typography>
          </ContainerText>
        }
        placement={placement}
      >
        {children}
      </Tooltip>
    ),
    icon: () => (
      <Tooltip
        title={
          <ContainerIcon>
            {tooltipText}
            <Icon type="boockmark" width={15} height={30} />
          </ContainerIcon>
        }
        arrow
        placement={placement}
      >
        {children}
      </Tooltip>
    ),
  };
  return tooltip[type]() || tooltip.default();
};
