const ur = {
  Groups: {
    title: "گروپس",
  },
  GroupList: {
    group: "گروپ",
    dates: "کلاس کی مدت",
    report: "کلاس کی رپورٹ",
    statistics: "پلیٹ فارم کے اعداد و شمار",
    progress: "پلیٹ فارم کی پیش رفت",
    lessonsCount: "کلاسز منعقد ہو چکی ہیں",
    total: "کل گھنٹے",
    finished: "مکمل شدہ گھنٹے",
    left: "باقی گھنٹے",
    period: "مدت",
    teachers: "اساتذہ",
    attendance: "حاضری",
  },
  ModalGroupReport: {
    Download: "ڈاؤن لوڈ کریں",
    Teacher: "استاد",
    Course: "کورس",
    Schedule: "شیڈول",
    Hours: "تعلیمی گھنٹے",
    TotalHours: "کل گھنٹے",
    CompletedHours: "مکمل شدہ گھنٹے",
    LeftHours: "باقی گھنٹے",
    Compensation: "معاوضہ",
    Students: "طلباء",
    Duration: "مدت",
  },
  GroupFilter: {
    Company: "کمپنی",
    ActiveGroups: "فعال گروپس",
    ActiveGroupsNo: "نہیں",
    ActiveGroupsYes: "ہاں",
    SelectLevel: "سطح منتخب کریں",
    SearchStudent: "طالب علم تلاش کریں",
    SearchGroup: "گروپ تلاش کریں",
    SelectModule: "ماڈیول منتخب کریں",
    AllModules: "تمام ماڈیولز",
    StartDate: "شروع ہونے کی تاریخ",
    EndDate: "اختتامی تاریخ",
    WithLessonsWithoutTeachers: "استاد کے بغیر",
    WithLessonsOnlyTechnicalCancellationBefore: "منسوخیاں",
  },
  GroupDownload: {
    Download: "ڈاؤن لوڈ کریں",
    Groups: "گروپس",
    WithoutPrice: "قیمت کے بغیر گروپس",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "گروپ کا نام درج کریں",
    SelectGroup: "گروپ منتخب کریں",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "آپ کے لیے کوئی گروپس نہیں ہیں۔",
    SecondTextDefault: "براہ کرم اپنے مطالعہ کے کوآرڈینیٹر سے رابطہ کریں۔",
    FirstTextDistributor: "ظاہر کرنے کے لیے کوئی ڈیٹا نہیں ہے۔",
    SecondTextDistributor: "کچھ ڈیٹا درج کرنے کی کوشش کریں۔",
    FirstTextClasses: "ظاہر کرنے کے لیے کوئی ڈیٹا نہیں ہے۔",
    SecondTextClasses: "فلٹرز تبدیل کرنے کی کوشش کریں یا معاونت سے رابطہ کریں۔",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "اساتذہ نہیں ملے",
    SelectTeacher: "استاد منتخب کریں",
  },
  ShortWeekDay: {
    "0": "اتوار",
    "1": "پیر",
    "2": "منگل",
    "3": "بدھ",
    "4": "جمعرات",
    "5": "جمعہ",
    "6": "ہفتہ",
  },
  LessonStatuses: {
    completed: "منعقد کیا گیا۔",
    planned: "منصوبہ بندی کی",
    earlyCanceledByCustomer: "سننے والوں کی طرف سے جلد منسوخی",
    earlyCanceledBySchool: "استاد کی طرف سے جلد منسوخی",
    lateCanceledByCustomer: "سننے والوں کی طرف سے دیر سے منسوخی",
    lateCanceledBySchool: "استاد کی طرف سے تاخیر سے منسوخی",
    notPerformedToPay: "ادائیگی کے لیے",
  },
};

export default ur;
