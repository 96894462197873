import { useState, KeyboardEvent, ChangeEvent } from "react";

import { observer } from "mobx-react-lite";

import { Search } from "../../components/Search";

export interface SearchFilterProps {
  placeholder?: string | null;
  value?: string | null;
  onChange?: (_: string) => void;
}

export const SearchFilter = observer(
  ({ placeholder, value, onChange }: SearchFilterProps): JSX.Element => {
    const [currentValue, setCurrentValue] = useState(value);

    const notifySearchChange = () => {
      if (currentValue === value) {
        return;
      }
      onChange?.(currentValue ?? "");
    };

    const handleSearchEnter = (event: KeyboardEvent) => {
      if (event.code !== "Enter") {
        return;
      }
      notifySearchChange();
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(event.target.value);
    };

    return (
      <Search
        placeholder={placeholder ?? ""}
        value={currentValue}
        onChange={handleSearchChange}
        onBlur={notifySearchChange}
        onKeyDown={handleSearchEnter}
      />
    );
  }
);
