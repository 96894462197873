import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActiveGroupsContainer } from "./styledComponents/ActiveGroupsContainer";
import { Filters } from "../../AttendanceReports";
import { DatePicker } from "../../components/DatePicker";

export interface AttendanceReportsDateRangeFilterProps {
  methods: UseFormReturn<Filters>;
}

export const AttendanceReportsDateRangeFilter = observer(
  ({ methods }: AttendanceReportsDateRangeFilterProps): JSX.Element => {
    const { control } = methods;

    const { t } = useTranslation();

    return (
      <ActiveGroupsContainer>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value ?? null}
                onChange={field.onChange}
                label={t("GroupFilter:StartDate")}
              />
            )}
          />

          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value ?? null}
                onChange={field.onChange}
                label={t("GroupFilter:EndDate")}
              />
            )}
          />
        </Box>
      </ActiveGroupsContainer>
    );
  }
);
