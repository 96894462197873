const tt = {
  Exercise: {
    Units: "Бүлекләр",
    Blocks: "Блоклар",
    Exercises: "Күнегүләр",
    Unit: "Бүлек",
    Block: "Блок",
    Exercise: "Күнегү",
    Script: "Видеога скрипт",
    HideScript: "Скриптны яшерергә",
    ShowScript: "Скриптны күрсәтергә",
    LanguageSamples: "Тел үрнәкләре",
    Extras: "Өстәмә материаллар",
    ToolReload: "Яңартырга",
    ToolTranslate: "Тәрҗемә итәргә",
    ToolCheck: "Тикшерергә",
    ToolNext: "Алга",
    IsCheckedWarning: "Бирем инде тикшерелгән",
    NotFilledWarning: "Сез барлык сорауларга да җавап бирмәдегез",
    Translate: "Тәрҗемә",
    Vocabulary: "Лексика",
    NoTranslation: "Тәрҗемә юк",
    AddToDictionary: "Сүзлеккә өстәргә",
    SelectedTranslationsWarning: "1-3 тәрҗемә сайларга кирәк",
    TranslateWordFailed: "Сүзне тәрҗемә итеп булмады",
    AddWordsFailed: "Сүзләрне өстәп булмады",
    AddWordsSuccess: "Сүзләр уңышлы өстәлде",
    AddedBefore: "Элегрәк өстәлгән иде",
    EnterText: "Текстны языгыз",
    Characters: "Символлар",
    Words: "Сүзләр",
    TextSize: "Текст күләме:",
    WordsAtMinimum: "Минимум сүзләр",
    LearnWords: "Сүзләрне өйрәнергә",
    Ok: "Ок./Яхшы",
    Expand: "Ачып җибәрергә",
    Collapse: "Ябып торырга",
    Close: "Ябарга",
    ResizeTooltip: "Уку өчен зурайтырга",
    ResetChangesTooltip: "Барлык үзгәрешләрне бетерергә",
    NoChange: "Үзгәрешсез",
    CorrectAnswer: "Дөрес җавап",
    DevTooltip: "Бу күнегү эшкәртүдә, иске интерфейска күчү өчен",
    FollowLink: "Сылтама буенча күчегез",
    CreativeExercisePassed: "Күнегү тикшерүгә җибәрелде",
    CreativeExerciseVerified: "Эшегез тикшерелгән. Басыгыз",
    CreativeExerciseVerifiedLink: "монда",
    DialogExerciseConfirmAnswer: "Расларга",
    DialogExerciseResetAnswer: "Яңадан язарга",
    DialogExerciseSelectAnswer: "Бу",
    DialogExerciseHelperLegend: "Күнегүне ничек эшләргә",
    DialogExerciseFinishHelperLegend: "Котлыйбыз!",
    DialogExerciseFirstHelper:
      "Күңелегездән түбәндәге исемлектән җавапны сайлагыз, микрофонга басып әйтегез.",
    DialogExerciseSecondHelper:
      "Бик яхшы, система сезнең сөйләмегезне таныды! Җавабыгызны раслагыз, яки аны яңадан яздырыгыз.",
    DialogExerciseThirdHelper:
      "Тавыш белән яздыру мөмкинлегегез бетте, вариантларның берсен кулдан сайлагыз.",
    DialogExerciseFinishHelper:
      "Сез биремне үтәдегез. Диалогны тыңлый аласыз яки Тикшерү төймәсенә басып киләсе биремгә күчә аласыз.",
    DialogExerciseRecognitionError:
      "Кызганычка каршы, система җавабыгызны танымады, яңадан эшләп карагыз.",
    AudioRecorderText: "Язу өчен басыгыз ({{count}} сек)",
    RadioLineHelperTextLeft: "Барлык дөрес җаваплар бирелмәгән. Тагын табыгыз",
    RadioLineHelperTextRight: "дөрес җаваплар.",
    NextDisabledWarning: "Күнегүне тикшерегез",
    BrokenError: "Бу күнегү эшкәртүдә, аны иске интерфейста башкарыгыз.",
    BrokenErrorLink: "Сылтама",
  },
  ResultsModals: {
    UnitTitle: "Бүлек нәтиҗәләре {{name}}",
    BlockTitle: "Блок нәтиҗәләре {{name}}",
    Passed: "Дөрес: {{count}}",
    Wrong: "Хаталар: {{count}}",
    Skipped: "Калдырылган: {{count}}",
    Blocks: "Блоклар",
    Block: "Блок {{order}}: {{name}}",
    Exercises: "Күнегүләр",
    Exercise: "Күнегү {{order}}: {{name}}",
    Repeat: "Кабатларга",
    Continue: "Дәвам итәргә",
    DontShowAgain:
      "Бу тәрәзне башка күрсәтмәскә (профиль көйләүләрендә яңадан кушарга була)",
    TestUnitTitle: "Котлыйбыз! Сез {{order}} тест этабын тәмамладыгыз",
    TestUnitResultTitle: "Нәтиҗәгез -",
    TestUnit: "Бүлек {{order}}. {{name}}",
    TestUnitTotalProgress: "Гомуми күтәрелеш",
    TestUnitResults: "Нәтиҗәләр",
    TestUnitNext: "Киләсе этапны башлау",
    TestUnitScoredPoints_zero: "{{count}}/{{total}} балл",
    TestUnitScoredPoints_one: "{{count}}/{{total}} балл",
    TestUnitScoredPoints_few: "{{count}}/{{total}} балл",
    TestUnitScoredPoints_many: "{{count}}/{{total}} балл",
    TestCourseTitle: "Котлыйбыз! Сез тестны тәмамладыгыз",
    TestCourseRecomendedLevel:
      "Сезгә (ниндидер) дәрәҗә өчен программа тәкъдим ителә -",
    TestCourseTotalProgress: "Гомуми күтәрелеш",
    TestCourseResultProgress: "Нәтиҗәләр",
    TestCourseShowResults: "Нәтиҗәләрне карарга",
    TestCourseShowHourse: "Ак. сәг. саны",
    TestCourseHint:
      "Барлык сөйләм күнекмәләрен үстерүгә басым ясала (сөйләм, тыңлау, уку һәм язу)",
    TestCourseA1Long:
      "Сөйләшүнең төп мәгънәсен аңлыйм. Дусларым һәм гаиләм турында сөйли алам",
    TestCourseA2Long:
      "Аерым җөмләләрне аңлыйм һәм көндәлек темаларга сөйләшә алам",
    TestCourseB1Long:
      "Фигыльләрнең барлык вакытларын кулланып, күпчелек очракларда аралаша беләм",
    TestCourseB2Long:
      "Үз карашымны яклый алам, сөйләшүләр һәм язышулар алып бара алам",
    TestCourseC1Long: "Әдәбиятны иркен укыйм һәм презентацияләр ясый беләм",
    TestCourseC2Long:
      "Туган телендә сөйләшүче кебек теләсә нинди тема буенча аралашам",
    TestCourseA1Short: "башлангыч",
    TestCourseA2Short: "элементар",
    TestCourseB1Short: "уртача",
    TestCourseB2Short: "уртачадан югарырак",
    TestCourseC1Short: "алга киткән",
    TestCourseC2Short: "камиллектә",
  },
  TestingCourseResult: {
    ModalTitle: "Тест нәтиҗәләре",
    Confirm: "Нәтиҗәләргә күчәргә",
    ModalBodyEntrance:
      "Хөрмәтле {{fullName}}, Сезгә CEFR шкаласы буенча {{cefrLevel}} дәрәҗә курсы тәкъдим ителә.",
    ModalBodyFinal:
      'Хөрмәтле {{fullName}}, Сез тестны тәмамладыгыз, $t(TestingCourseResult:courseMark, {"count": {{courseMark}} }) алып {{maxMark}}.\nНәтиҗәгез - {{percentage}}%.',
    ModalBodyDefault: "Хөрмәтле {{fullName}}, Сез тестны уңышлы тәмамладыгыз.",
    courseMark_zero: "{{count}} балл",
    courseMark_one: "{{count}} балл",
    courseMark_few: "{{count}} балл",
    courseMark_many: "{{count}} балл",
  },
};

export default tt;
