/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import React from "react";

import * as Icons from "../../assets/icons/index";

export type IconType =
  | "arrowRight"
  | "calendar"
  | "user"
  | "boockmark"
  | "empty"
  | "checkmark"
  | "sortAmountDown"
  | "sortAmountUp"
  | "chevronDown";

export interface IconProps {
  type: IconType;
  color?: string;
  stroke?: string;
  width?: number | string;
  height?: number | string;
  onClick?: (e: React.MouseEvent) => void;
  sizeSquareIcon?: number | string;
}

type IIcon = {
  [key in IconType]: React.ReactElement;
};

export const Icon = ({
  type,
  color,
  stroke,
  onClick,
  width,
  height,
  sizeSquareIcon,
}: IconProps): JSX.Element => {
  const defaultProps = {
    stroke,
    color,
    onClick,
    width: width || sizeSquareIcon,
    height: height || sizeSquareIcon,
  };

  const icon: IIcon = {
    arrowRight: <Icons.ArrowRight {...defaultProps} />,
    calendar: <Icons.Calendar {...defaultProps} />,
    boockmark: <Icons.Boockmark {...defaultProps} />,
    checkmark: <Icons.CheckMark {...defaultProps} />,
    sortAmountDown: <Icons.SortAmountDown {...defaultProps} />,
    sortAmountUp: <Icons.SortAmountUp {...defaultProps} />,
    chevronDown: <Icons.ChevronDown {...defaultProps} />,
    empty: <Icons.Empty {...defaultProps} />,
    user: <Icons.User {...defaultProps} />,
  };

  return icon[type];
};
