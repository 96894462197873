import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";

import { StyledPaper } from "components/StyledPaper";

export const CompaniesReportSummary = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const { companyReport } = useStores();

  if (!companyReport.summary) return null;

  return (
    <StyledPaper
      sx={{
        px: "37px",
        py: "10px",
        mb: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <Typography variant="smallText" color="custom.grey.dark">
        {t("CompaniesReport:SummaryCompanies")}:{" "}
        {companyReport.summary.totalCompanies}
      </Typography>
      <Typography variant="smallText" color="custom.grey.dark">
        {t("CompaniesReport:SummaryGroups")}:{" "}
        {companyReport.summary.totalGroups}
      </Typography>
      <Typography variant="smallText" color="custom.grey.dark">
        {t("CompaniesReport:SummaryPayment")}:{" "}
        {Number(companyReport.summary.totalPayment).toFixed(1)}
      </Typography>
      <Typography variant="smallText" color="custom.grey.dark">
        {t("CompaniesReport:SummaryHours")}:{" "}
        {companyReport.totalHours.toFixed(1)}
      </Typography>
      <Typography variant="smallText" color="custom.grey.dark">
        {t("CompaniesReport:SummaryAverageHourPrice")}:{" "}
        {companyReport.averageHourPrice.toFixed(1)}
      </Typography>
    </StyledPaper>
  );
});
