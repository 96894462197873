import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

import { useStores } from "@packages/store/models";

import { Icon } from "components/Icon";
import { JetLink } from "components/JetLink";
import { ROUTES } from "router/constants";

import {
  ReferenceBookBlock,
  ReferenceBookBlockTitle,
  ReferenceBookGroup,
} from "./styledComponents";

export const ReferenceBooks = observer((): JSX.Element | null => {
  const { taggingList } = useStores();

  return (
    <Box>
      {taggingList.items.map((list) => (
        <Box key={list.id} mb="1rem">
          <JetLink
            to={`${ROUTES.TAGGING_LIST}/${list.id}`}
            sx={{
              fontSize: "1.125rem",
              fontWeight: "bold",
              display: "block",
              mb: "0.5rem",
            }}
          >
            {list.name}
          </JetLink>
          {/* <ReferenceBookGroupTitle>{taggingList.name}</ReferenceBookGroupTitle> */}
          <ReferenceBookGroup>
            {list.tags.map((tag) => (
              <ReferenceBookBlock key={tag.id}>
                <Icon type="dashboard" sizeSquareIcon={22} />
                <ReferenceBookBlockTitle>{tag.name}</ReferenceBookBlockTitle>
                {/* <Typography variant="h3" color="custom.grey.accentText">
                  {item.count}
                </Typography> */}
              </ReferenceBookBlock>
            ))}
            {/* <ReferenceBooksButton>
              Другие
              <Icon type="bxArrowRight" sizeSquareIcon={16} />
            </ReferenceBooksButton> */}
          </ReferenceBookGroup>
        </Box>
      ))}
    </Box>
  );
});
