import { useState } from "react";

import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import groupBy from "lodash/groupBy";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";
import { Teacher } from "@packages/store/models/Teacher/Teacher";
import { TeacherRateModel } from "@packages/store/models/TeacherRate/TeacherRate";

import { Icon } from "components/Icon";
import { classTypes } from "constants/constants";
import { useFetch } from "hooks/useFetch";
import { LangDetails } from "pages/TeacherDetails/components";
import { formatRate } from "pages/TeacherDetails/helpers/formatRate";
import { sortRateByDate } from "pages/TeacherDetails/helpers/sortRateByDate";
import { StyledButton } from "pages/TeacherDetails/styledComponents";

import {
  ModalTeacherLang,
  ModalTeacherLangFormValues,
} from "../ModalTeacherLang";
import {
  ModalTeacherRate,
  ModalTeacherRateFormValues,
} from "../ModalTeacherRate";

interface RatesProps {
  teacher: Teacher;
}

export const Rates = observer(({ teacher }: RatesProps): JSX.Element | null => {
  const { t } = useTranslation();

  const {
    teacherRate: teacherRateStore,
    language: languageStore,
    api,
    addError,
  } = useStores();

  const rates = teacherRateStore.getByTeacherId(teacher.actualId);

  const [selectedLangId, setSelectedLangId] = useState<string | null>(null);
  const [selectedRateId, setSelectedRateId] = useState<string | null>(null);

  const selectedRate =
    computed(() => rates.find((rate) => rate.id === selectedRateId)).get() ??
    null;

  const mode = selectedRate ? "change" : "new";

  const [isLangModalOpen, setLangModalOpen] = useState(false);
  const openLangModal = () => setLangModalOpen(true);
  const closeLangModal = () => setLangModalOpen(false);

  const [isRateModalOpen, setRateModalOpen] = useState(false);
  const openRateModal = () => setRateModalOpen(true);
  const closeRateModal = () => setRateModalOpen(false);

  const listLangs = languageStore.items.map(({ id, name }) => ({
    value: id,
    label: t(`Languages:${name}`),
  }));

  const listClassTypes = classTypes.map(({ label }) => ({
    value: label,
    label,
  }));

  const { fetch: createRate } = useFetch(
    (data: any) => {
      return api?.createTeacherRate(data) as any;
    },
    (rate: any) => {
      teacherRateStore?.addItem(TeacherRateModel.create(rate) as any);
      closeRateModal();
      closeLangModal();
    },
    () => addError(t("TeacherDetails:ApiError"))
  );

  const handleCreateLang = (data: ModalTeacherLangFormValues) => {
    Promise.all(
      data.rate.map((rate) =>
        createRate({
          ...rate,
          languageId: data.lang,
          teacherId: teacher.actualId,
        })
      )
    );
  };

  const handleSubmitRate = async (data: ModalTeacherRateFormValues) => {
    if (mode === "change") {
      if (!selectedRate) return false;

      const isRateUpdated = await selectedRate.updateTeacherRate({
        ...data,
        activeFrom: format(data.activeFrom, "dd.MM.yyyy"),
      });

      if (isRateUpdated) {
        return closeRateModal();
      }

      return addError(t("TeacherDetails:ApiError"));
    }

    if (mode === "new") {
      createRate({
        ...data,
        teacherId: teacher.actualId,
        languageId: selectedLangId,
      });
    }

    return true;
  };

  const handleRequestRateEdit = (rateId: string) => {
    setSelectedRateId(rateId);
    openRateModal();
  };

  const handleRequestRateCreate = (langId: string) => () => {
    setSelectedRateId(null);
    setSelectedLangId(langId);
    openRateModal();
  };

  const formattedRates = computed(() =>
    Object.entries(
      groupBy(rates.map(formatRate).sort(sortRateByDate), "languageId")
    )
  ).get();

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="1rem"
      >
        <Typography variant="h2" color="custom.gray.dark">
          {t("TeacherDetails:RatesTitle")}
        </Typography>
        <StyledButton variant="add" onClick={openLangModal}>
          <Icon type="bookAdd" sizeSquareIcon={16} />
          {t("TeacherDetails:RatesAddLang")}
        </StyledButton>
      </Box>

      {formattedRates.map(([languageId, languageRates]) => (
        <LangDetails
          key={languageId}
          languageId={languageId}
          rates={languageRates}
          onRequestRateCreate={handleRequestRateCreate(languageId)}
          onRequestRateEdit={handleRequestRateEdit}
        />
      ))}

      <ModalTeacherLang
        open={isLangModalOpen}
        onClose={closeLangModal}
        onSubmit={handleCreateLang}
        listLangs={listLangs}
        listClassTypes={listClassTypes}
      />

      <ModalTeacherRate
        title={
          mode === "change"
            ? t("TeacherDetails:ChangeRateModalTitle")
            : t("TeacherDetails:CreateRateModalTitle")
        }
        open={isRateModalOpen}
        onClose={closeRateModal}
        initialData={selectedRate}
        onSubmit={handleSubmitRate}
        listClassTypes={listClassTypes}
      />
    </Box>
  );
});
