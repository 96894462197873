import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import type { Teacher } from "@packages/store/models/Teacher/Teacher";

import { JetLink } from "components/JetLink";
import { Table } from "components/Table";
import { TableCell } from "pages/shared/styledComponents";
import {
  formatTeacher,
  FormattedTeacher,
} from "pages/Teachers/helpers/formatTeacher";
import { ROUTES } from "router/constants";

// import { Langs } from "../Langs";

interface TeachersTableProps {
  teachers: Teacher[];
}

export const TeachersTable = observer(
  ({ teachers }: TeachersTableProps): JSX.Element | null => {
    const { t } = useTranslation();

    const columns = [
      {
        Header: t("Teachers:TableFullName"),
        accessor: "fullName",
        Cell: ({ row, value }: Cell<FormattedTeacher, string>) => (
          <TableCell>
            <JetLink to={`${ROUTES.TEACHERS}/${row.original.id}`}>
              {value}
            </JetLink>
          </TableCell>
        ),
      },
      // {
      //   Header: "E-mail",
      //   accessor: "email",
      //   Cell: ({ value }: Cell<FormattedTeacher, string>) => (
      //     <TableCell color="custom.grey.main">
      //       <JetLink to={`mailto:${value}`} external>
      //         {value}
      //       </JetLink>
      //     </TableCell>
      //   ),
      // },
      // {
      //   Header: "Телефон",
      //   accessor: "phone",
      //   Cell: ({ value }: Cell<FormattedTeacher, string>) => (
      //     <TableCell color="custom.grey.main">{value}</TableCell>
      //   ),
      // },
      // {
      //   Header: "Языки",
      //   accessor: "langs",
      //   Cell: ({ value }: Cell<FormattedTeacher, string[]>) => (
      //     <TableCell>
      //       <Langs langs={value} />
      //     </TableCell>
      //   ),
      // },
    ];

    const formattedTeachers = computed(() => teachers.map(formatTeacher)).get();

    return (
      <Table
        columns={columns}
        data={formattedTeachers}
        count={10}
        containerType="blue"
      />
    );
  }
);
