import { Alert, Snackbar } from "@mui/material";
import { observer } from "mobx-react-lite";

import { useStores } from "@packages/store/models";

import { useError } from "hooks/useError";

export const ToastErrorMessage = observer((): JSX.Element => {
  const store = useStores();

  const { error } = store;

  const { openSnackBar, closeSnackBar } = useError(store);

  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={6000}
      onClose={closeSnackBar}
    >
      <Alert onClose={closeSnackBar} severity="error" sx={{ width: "100%" }}>
        {error}
      </Alert>
    </Snackbar>
  );
});
