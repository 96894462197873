import { StyledButton } from "./styledComponents/StyledButton";
import { Icon, IconType } from "../../components/Icon";

export interface ButtonWithIconProps {
  icon?: IconType;
  title: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

export const ButtonWithIcon = ({
  icon,
  title,
  fullWidth,
  onClick,
}: ButtonWithIconProps): JSX.Element => {
  return (
    <StyledButton fullWidth={fullWidth} onClick={onClick}>
      {icon && <Icon type={icon} width={24} />}
      {title}
    </StyledButton>
  );
};
