import { Typography, TypographyProps, styled } from "@mui/material";

export const Text = styled((props: TypographyProps) => (
  <Typography variant="regularText" {...props} />
))(() => ({
  flex: 1,
  textAlign: "center",
  img: {
    width: "100%",
    height: 100,
  },
  video: {
    width: "100%",
    height: 100,
  },
}));
