import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TitleProps {
  titleForModule: string;
}

export const Title = ({ titleForModule }: TitleProps) => {
  const { t } = useTranslation();

  return (
    <Typography variant="h1" component="span" sx={{ fontWeight: 400 }}>
      {t("Groups:title")} {titleForModule}
    </Typography>
  );
};
