import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router";

import { GroupReport as PackageGroupReport } from "@packages/groups";

export const GroupReport = observer((): JSX.Element => {
  const navigate = useNavigate();

  const { id } = useParams();

  return <PackageGroupReport id={id} onClose={() => navigate(-1)} />;
});
