import { parseSearchParams } from "@packages/shared/utils/searchParams";

export const getDefaultValues = (searchParams: URLSearchParams) => {
  const data = parseSearchParams(searchParams);

  return {
    startDate: data?.startDate ?? null,
    endDate: data?.endDate ?? null,
    companyId: data?.companyId ?? "",
    groupId: data?.groupId ?? "",
    teacherId: data?.teacherId ?? "",
    status: data?.status ?? "",
    sampleByStatus: data?.sampleByStatus ?? false,
    onlyLessonsWithoutGroup: data?.onlyLessonsWithoutGroup ?? false,
  } as any;
};
