import { useEffect, useState } from "react";

import { format, subMonths, startOfMonth, endOfMonth } from "date-fns";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";

import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import {
  CreateDistributorReportData,
  DistributorReportType,
  GetLearningGroupsParams,
  Student,
  ValueWithLabel,
} from "@packages/store/services/Api";

import { CreateReportModal } from "components/CreateReportModal";
import { ROUTES } from "router/constants";

import {
  formatStudentsForFilter,
  formatGroupsForFilter,
  formatCompaniesForFilter,
  formatTestsForFilter,
  filterGroupsByCompanies,
  filterGroupsByGroups,
  filterGroupsByStudents,
  filterGroupsByReportTests,
  filterGroupByLicense,
} from "./helpers";
import { FilterType } from "./types";

export interface CreateReportFormProps {
  title: string;
  dateRange?: boolean;
}

export const CreateReportForm = observer(
  ({ title, dateRange }: CreateReportFormProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [apiError, setApiError] = useState<string | null>(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [filterType, setFilterType] = useState("");
    const [filterSelected, setFilterSelected] = useState<string[]>([]);
    const [filterList, setFilterList] = useState<ValueWithLabel[]>([]);
    const [filterTypes, setFilterTypes] = useState<ValueWithLabel[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<ValueWithLabel[]>([]);

    const [fetchedGroupCompanies, setFetchedGroupCompanies] = useState<
      string[]
    >([]);

    const {
      auth,
      learningGroup,
      lesson,
      reportTest,
      customerCompany,
      loading,
      distributorReport,
      api,
    } = useStores();

    const { loading: loadingGroups, fetch: fetchGroups } = useCancelableFetch();

    const { loading: loadingTests, fetch: fetchTests } = useCancelableFetch();

    const { loading: loadingCompanyGroups, fetch: fetchCompanyGroups } =
      useCancelableFetch();

    const { createReport } = distributorReport;

    const { user } = auth;
    const { items: lessons } = lesson;
    const { items: groups } = learningGroup;
    const { items: tests } = reportTest;
    const { items: companies } = customerCompany;

    const reportTests = tests.filter((test) => {
      const ids = groups.map(({ id }) => id);
      return ids.includes(String(test.groupId ?? 0));
    });

    const { STUDENTS_REPORTS, TEACHERS_REPORTS } = ROUTES;
    const { students, teachers } = DistributorReportType;

    const types = [
      {
        value: students,
        label: t("CreateReport:StudentType"),
      },
      {
        value: teachers,
        label: t("CreateReport:TeacherType"),
      },
    ];

    const pathTypes: Record<string, string> = {
      [`${STUDENTS_REPORTS}/create`]: students,
      [`${TEACHERS_REPORTS}/create`]: teachers,
    };
    const currentPathType = pathTypes[pathname] ?? "";

    // Если дата только одна, то по-умолчанию нужно указать сегодня
    // если даты две, то нужно указать предыдущий месяц
    const defaultDate = {
      from: dateRange
        ? format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd")
        : (format(new Date(), "yyyy-MM-dd") as any),
      to: dateRange
        ? (format(endOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd") as any)
        : null,
    };

    const handleClose = () => {
      navigate(pathname.replace("/create", ""));
    };

    const handleSubmit = async (data: CreateDistributorReportData) => {
      setApiError(null);
      const sendData = { ...data };
      if (!sendData.groups.length) {
        setApiError(t("CreateReport:EmptyGroupsError"));
        return;
      }
      if (sendData.to === null) {
        sendData.to = sendData.from;
      }
      setSubmitLoading(true);
      const isCreated = await createReport(sendData);
      setSubmitLoading(false);
      if (isCreated) {
        handleClose();
      } else {
        setApiError(t("CreateReport:OrderCreateError"));
      }
    };

    const handleTypeChange = (type: string) => {
      const pathes: Record<string, string> = {
        [students]: `${STUDENTS_REPORTS}/create`,
        [teachers]: `${TEACHERS_REPORTS}/create`,
      };
      const path = pathes[type] ?? "";
      navigate(path, { replace: true });
    };

    const getSelectedLabel = (count: number): string => {
      const { student, group, company, test } = FilterType;
      const keys: Record<string, string> = {
        [student]: "CreateReport:FilterListSelectedStudents",
        [group]: "CreateReport:FilterListSelectedGroups",
        [company]: "CreateReport:FilterListSelectedCompanies",
        [test]: "CreateReport:FilterListSelectedTests",
      };

      return t(keys[filterType], { count });
    };

    const getChipsLabel = (): string => {
      const { student, group, company, test } = FilterType;
      const keys: Record<string, string> = {
        [student]: "CreateReport:FilterListSelectedPlaceholderStudents",
        [group]: "CreateReport:FilterListSelectedPlaceholderGroups",
        [company]: "CreateReport:FilterListSelectedPlaceholderCompanies",
        [test]: "CreateReport:FilterListSelectedPlaceholderTests",
      };

      return t(keys[filterType]);
    };

    useEffect(() => {
      const { group, student } = FilterType;
      const filters: Record<string, GetLearningGroupsParams> = {
        [group]: { unlicensed: false },
        [student]: {
          unlicensed: false,
          includeLessons: true,
        },
      };

      const filter = filters[filterType];

      if (!filter) {
        return;
      }

      fetchGroups((token) => api.getLearningGroups(filter, token));
    }, [filterType, fetchGroups]);

    useEffect(() => {
      const { isDistributor = false, isCoordinator = false } = user ?? {};
      const showCompanyFilter = isDistributor || isCoordinator;
      const { company, student } = FilterType;
      const list = [
        {
          label: t("CreateReport:FilterListGroup"),
          value: FilterType.group,
        },
        {
          label: t("CreateReport:FilterListStudent"),
          value: FilterType.student,
        },
        ...(showCompanyFilter
          ? [
              {
                label: t("CreateReport:FilterListCompany"),
                value: FilterType.company,
              },
            ]
          : []),
        {
          label: t("CreateReport:FilterListTest"),
          value: FilterType.test,
        },
      ];
      setFilterTypes(list);

      setFilterType(isDistributor ? company : student);
    }, [user, setFilterTypes, setFilterType]);

    useEffect(() => {
      if (loading) {
        return;
      }
      const { student, group, company, test } = FilterType;
      const lists: Record<string, ValueWithLabel[]> = {
        [student]: formatStudentsForFilter(
          lessons.flatMap(({ students }) => students) as Student[]
        ),
        [group]: formatGroupsForFilter([
          ...groups.filter(filterGroupByLicense),
        ]),
        [company]: formatCompaniesForFilter([...companies]),
        [test]: formatTestsForFilter([...reportTests]),
      };

      setFilterList(lists[filterType] ?? []);
    }, [
      filterType,
      loading,
      loadingGroups,
      lessons,
      groups,
      groups.length,
      companies,
      companies.length,
      tests,
      tests.length,
      setFilterList,
    ]);

    useEffect(() => {
      const { student, group, test, company } = FilterType;

      const licensedGroups = groups.filter(filterGroupByLicense);

      const filters: Record<string, LearningGroup[]> = {
        [company]: filterGroupsByCompanies(licensedGroups, filterSelected),
        [group]: filterGroupsByGroups(licensedGroups, filterSelected),
        [student]: filterGroupsByStudents(licensedGroups, filterSelected),
        [test]: filterGroupsByReportTests(
          licensedGroups,
          tests.filter(({ id }) => filterSelected.includes(id))
        ),
      };

      const list = filters[filterType] ?? [];
      const formattedGroups = formatGroupsForFilter([...list]);
      setSelectedGroups(formattedGroups);
    }, [
      filterType,
      filterSelected,
      tests,
      tests.length,
      groups,
      groups.length,
      setSelectedGroups,
    ]);

    useEffect(() => {
      if (filterType !== FilterType.company) {
        return;
      }
      filterSelected
        .filter((companyId) => !fetchedGroupCompanies.includes(companyId))
        .forEach((companyId) => {
          setFetchedGroupCompanies([...fetchedGroupCompanies, companyId]);
          const filters = {
            includeLessons: true,
            unlicensed: false,
            company: companyId,
          };
          fetchCompanyGroups((token) => api.getLearningGroups(filters, token));
        });
    }, [
      filterType,
      fetchedGroupCompanies,
      filterSelected,
      fetchCompanyGroups,
      setFetchedGroupCompanies,
    ]);

    useEffect(() => {
      if (filterType !== FilterType.test) {
        return;
      }
      fetchTests((token) => api.getReportTests(token));
    }, [fetchTests, filterType]);

    return (
      <CreateReportModal
        title={title}
        defaultDate={defaultDate}
        apiError={apiError}
        types={types}
        type={currentPathType}
        onTypeChange={handleTypeChange}
        onSubmit={handleSubmit}
        onClose={handleClose}
        dateRange={dateRange}
        loading={loading}
        submitLoading={submitLoading}
        filterProps={{
          types: filterTypes,
          type: filterType,
          list: filterList,
          listLoading: loadingGroups || loadingTests,
          selected: filterSelected,
          checkboxLabel: getSelectedLabel(filterSelected.length),
          chipsLabel: getChipsLabel(),
          onTypeChange: setFilterType,
          onSelectedChange: setFilterSelected,
        }}
        groupProps={{
          groupList: selectedGroups,
          loading: loadingCompanyGroups,
          onGroupListChange: setSelectedGroups,
        }}
      />
    );
  }
);
