import {
  Autocomplete,
  Box,
  Stack,
  FormControlLabel,
  Switch,
  Typography,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";
import {
  Lesson,
  statuses as lessonStatuses,
} from "@packages/store/models/Lesson/LessonModel";

import { Filters } from "../../Classes";
import { getAutocompleteFieldValue } from "../../helpers/getAutocompleteFieldValue";
import { DatePicker } from "../DatePicker";
import { FilterSelect } from "../FilterSelect";
import { GroupsAutocompleteFilter } from "../GroupsAutocompleteFilter";
import { LessonsDownload } from "../LessonsDownload";
import { TeachersAutocompleteFilter } from "../TeachersAutocompleteFilter";

interface LessonFilterProps {
  methods: UseFormReturn<Filters>;

  lessonsToDownload: Lesson[];
  showAdditionalFilters: boolean;
  showTeacherFilter: boolean;
}

export const LessonFilter = observer(
  (props: LessonFilterProps): JSX.Element | null => {
    const {
      methods,
      lessonsToDownload,
      showAdditionalFilters,
      showTeacherFilter,
    } = props;

    const { control, watch } = methods;

    const { t } = useTranslation();

    const stores = useStores();

    const { customerCompany: customerCompanyStore } = stores;

    const companies = [
      ...customerCompanyStore.items
        .map(({ id, name }) => ({
          value: id,
          label: String(name),
        }))
        .sort((prev, next) => prev.label.localeCompare(next.label)),
    ];

    const statuses = [
      {
        value: "",
        label: t("Classes:None"),
      },
      ...lessonStatuses.map((status) => ({
        value: status,
        label: t(`LessonStatuses:${status}`),
      })),
    ];

    // Почему-то полностью через контроллер не работает
    const companyId = watch("companyId");

    return (
      <Box>
        {showAdditionalFilters && (
          <Stack direction="row" gap="1rem" alignItems="center" mb={4}>
            <Controller
              name="sampleByStatus"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch checked={value} onChange={(_, v) => onChange(v)} />
                  }
                  label={
                    <Typography variant="regularText">
                      {t("Classes:SampleByStatus")}
                    </Typography>
                  }
                />
              )}
            />

            <Controller
              name="onlyLessonsWithoutGroup"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch checked={value} onChange={(_, v) => onChange(v)} />
                  }
                  label={
                    <Typography variant="regularText">
                      {t("Classes:OnlyLessonsWithoutGroup")}
                    </Typography>
                  }
                />
              )}
            />
          </Stack>
        )}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="1rem"
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  value={field.value ?? null}
                  onChange={field.onChange}
                  label={t("DateRangePicker:StartDate")}
                />
              )}
            />

            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  value={field.value ?? null}
                  onChange={field.onChange}
                  label={t("DateRangePicker:EndDate")}
                />
              )}
            />
          </Box>
          <LessonsDownload lessons={lessonsToDownload} />
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: 4,
            py: 3,
          }}
        >
          <Controller
            name="companyId"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Autocomplete
                  disablePortal
                  openOnFocus
                  options={companies}
                  sx={{ minWidth: 200, marginTop: "0.5rem" }}
                  value={getAutocompleteFieldValue(companies, companyId)}
                  onChange={(_, v) => onChange(v?.value)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={t("Classes:Company")}
                    />
                  )}
                />
              );
            }}
          />

          <Controller
            name="groupId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <GroupsAutocompleteFilter onChange={onChange} group={value} />
            )}
          />

          {showTeacherFilter && (
            <Controller
              name="teacherId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TeachersAutocompleteFilter
                  teacherId={value}
                  onChange={onChange}
                />
              )}
            />
          )}

          <Controller
            name="status"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FilterSelect
                value={value}
                data={statuses}
                onChange={(event) => onChange(event.target.value)}
                placeholder={t("Classes:Status")}
              />
            )}
          />
        </Box>
      </Box>
    );
  }
);
