import React, { useLayoutEffect, useState } from "react";

import { Box, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { TaggingListsFilledWithTeacher } from "@packages/store/models/TaggingList/TaggingListStore";
import { Teacher } from "@packages/store/models/Teacher/Teacher";

import DefaultTeacherPhoto from "assets/img/default-teacher-photo.jpg";
import { Button } from "components/Button";
import { FilterSelect } from "components/shared/FilterSelect";
import { Modal, ModalProps } from "components/shared/Modal";
import { TrimInput } from "components/TrimInput";
import { Photo } from "pages/TeacherDetails/styledComponents";

export interface ModalTeacherProfileProps
  extends Omit<ModalProps, "children" | "onSubmit"> {
  onSubmit: (data: ModalTeacherProfileFormValues) => void;
  teacher: Teacher;
  taggingLists: TaggingListsFilledWithTeacher[];
}

export interface ModalTeacherProfileFormValues {
  [listId: string]: string | undefined;
}

export const ModalTeacherProfile = observer(
  ({ onSubmit, teacher, taggingLists, ...other }: ModalTeacherProfileProps) => {
    const { t } = useTranslation();

    const [values, setValues] = useState<ModalTeacherProfileFormValues>({});

    const handleChange =
      (listId: string) => (e: SelectChangeEvent<unknown>) => {
        const { value } = e.target;

        if (typeof value === "string") {
          setValues((prev) => ({ ...prev, [listId]: value }));
        }
      };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      onSubmit(values);
    };

    useLayoutEffect(() => {
      setValues(
        taggingLists.reduce(
          (acc, { list, teacherValue }) =>
            Object.assign(acc, { [list.id]: teacherValue }),
          {}
        )
      );
    }, [taggingLists]);

    return (
      <Modal title={t("TeacherDetails:ProfileModalTitle")} {...other}>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexWrap="wrap" gap="3rem">
            <Box display="flex" flexDirection="column" flexGrow={1} gap="1rem">
              <Photo src={teacher.photo ?? DefaultTeacherPhoto} alt="Teacher" />
              <TrimInput
                variant="outlined"
                label={t("TeacherDetails:ProfileModalFormLastName")}
                value={teacher.lastName}
                disabled
              />
              <TrimInput
                variant="outlined"
                label={t("TeacherDetails:ProfileModalFormFirstName")}
                value={teacher.firstName}
                disabled
              />
              {/* <TrimInput
                variant="outlined"
                label={t("TeacherDetails:ProfileModalFormPhone")}
                value={teacher.phone}
                disabled
              /> */}
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1} gap="1rem">
              {Object.entries(values).map(([listId, teacherValue]) => {
                const taggingList = taggingLists.find(
                  ({ list }) => list.id === listId
                ) as TaggingListsFilledWithTeacher;
                const { list } = taggingList;
                const data = list.tags.map((tag) => ({
                  label: tag.name ?? "",
                  value: tag.id,
                }));

                return (
                  <FilterSelect
                    key={listId}
                    placeholder={list.name ?? ""}
                    data={data}
                    value={teacherValue ?? ""}
                    onChange={handleChange(listId)}
                  />
                );
              })}
              <Button
                variant="outlined"
                type="submit"
                sx={{ mt: "auto", py: "0.75rem" }}
              >
                {t("TeacherDetails:ProfileModalSubmit")}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    );
  }
);
