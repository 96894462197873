/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getRoot } from "mobx-state-tree";

import { RootStoreModel } from "..";
import { Translation } from "../Translation/TranslationModel";

const getLocale = (data: Translation[], locale: string) => {
  return data.find((item) => locale === item.locale);
};

/**
 * Adds a environment property to the node for accessing our
 * Environment in strongly typed.
 */
export const withTranslation = (self: any) => ({
  views: {
    /**
     * The environment.
     */
    get nameTranslated(): string {
      const locale =
        getRoot<typeof RootStoreModel>(self)?.auth?.user?.locale || "en";
      const { translationsAttributes = [] } = self;

      return getLocale(translationsAttributes, locale)?.name || "";
    },

    get descriptionTranslated(): string {
      const locale =
        getRoot<typeof RootStoreModel>(self)?.auth?.user?.locale || "en";
      const { translationsAttributes = [] } = self;

      return getLocale(translationsAttributes, locale)?.description || "";
    },
  },
});
