import { useEffect, useState } from "react";

import { Box, ThemeProvider, CssBaseline } from "@mui/material";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { I18nextProvider } from "react-i18next";

import { defaultTheme } from "@packages/shared/themes/default";
import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";
import { GetLearningGroupsParams } from "@packages/store/services/Api";

import { LessonFilter, LessonTable } from "./components";
import { BlockContainer } from "./components/BlockContainer";
import { ContentContainer } from "./components/ContentContainer";
import { Helper } from "./components/Helper";
import { Loading } from "./components/Loading";
import { Title } from "./components/Title";
import {
  filterByCompany,
  filterByGroup,
  filterByStatus,
  filterByDateRange,
  sortByDate,
  filterByTeacher,
  filterByGroupExistence,
} from "./helpers";
import { getDefaultValues } from "./helpers/getDefaultValues";
import i18n from "./locales/i18n";

const LESSONS_PER_PAGE = 10;

export interface Filters {
  startDate: Date | null;
  endDate: Date | null;
  companyId: string;
  groupId: string;
  teacherId: string;
  status: string;

  sampleByStatus: boolean;
  onlyLessonsWithoutGroup: boolean;
}

interface ClassesProps {
  searchParams: URLSearchParams;
  syncSearchParams: (data: Filters) => void;

  showGroupReport: (groupId: string) => void;
  showAttendanceReport: (lessonId: string) => void;
}

export const Classes = observer((props: ClassesProps): JSX.Element | null => {
  const {
    searchParams,
    syncSearchParams,
    showAttendanceReport,
    showGroupReport,
  } = props;

  const {
    lesson: lessonStore,
    auth: authStore,
    loading,
    api,
    // addError,
  } = useStores();

  const { user } = authStore;

  const {
    isTeacher = false,
    isCoordinator = false,
    isDistributor = false,
  } = user ?? {};

  const isDistributorOrCoordinator = isDistributor || isCoordinator;

  // const [groupsPage, setGroupsPage] = useState(1);
  // const [isAllGroupsFetched, setIsAllGroupsFetched] = useState(false);
  // const [canFetchMore, setCanFetchMore] = useState(false);

  const {
    fetch: fetchGroups,
    loading: groupsLoading,
    error: groupsError,
  } = useCancelableFetch();
  const { fetch: fetchCompanies } = useCancelableFetch();

  const [tablePage, setTablePage] = useState(0);

  const methods = useForm<Filters>({
    mode: "onChange",
    defaultValues: getDefaultValues(searchParams),
  });
  const { watch } = methods;
  const data = watch();
  const {
    startDate,
    endDate,
    companyId,
    groupId,
    teacherId,
    status,
    sampleByStatus,
    onlyLessonsWithoutGroup,
  } = data;

  const lessons = isTeacher ? lessonStore.teacherLessons : lessonStore.items;

  const isLessonsLoading = groupsLoading || lessonStore.loading;

  const filteredLessons = computed(() => {
    const result = lessons
      .filter(filterByGroupExistence(!onlyLessonsWithoutGroup))
      .filter(filterByCompany(companyId))
      .filter(filterByGroup(groupId))
      .filter(filterByTeacher(teacherId))
      .filter(filterByStatus(status))
      .filter(filterByDateRange(startDate, endDate))
      .sort(sortByDate);

    return result;
  }).get();

  const handleChangeTablePage = (page: number) => {
    setTablePage(page);

    // if (isLastPage && !isAllGroupsFetched) {
    //   setGroupsPage((p) => p + 1);
    // }
  };

  useEffect(() => {
    if (sampleByStatus) return;

    const filter: GetLearningGroupsParams = {
      includeLessons: true,
    };

    if (companyId) filter.company = companyId;
    if (groupId) filter.ids = [groupId];
    if (teacherId) filter.teacher = teacherId;

    if (!filter.company && !filter.ids && !filter.teacher) return;

    fetchGroups((token) => api.getLearningGroups(filter, token));
  }, [api, companyId, fetchGroups, groupId, sampleByStatus, teacherId]);

  useEffect(() => {
    if (!sampleByStatus) return;

    const filter: GetLearningGroupsParams = {
      // includeLessons: true,
      // limit: 100,
      // page: groupsPage,
    };

    // if (company) filter.company = company;
    // if (group) filter.ids = [group];
    // if (teacher) filter.teacher = teacher;
    if (startDate) filter.classGroupsFromDate = startDate;
    if (endDate) filter.classGroupsToDate = endDate;

    if (!filter.classGroupsFromDate || !filter.classGroupsToDate) return;

    fetchGroups((token) =>
      api.getLearningGroupsByLessonsStatus(
        {
          status,
          fromDate: filter.classGroupsFromDate,
          toDate: filter.classGroupsToDate,
        },
        token
      )
    );

    // fetchGroups((token) => api.getLearningGroups(filter, token)).then(
    //   (value: { learningGroups: LearningGroup[] } | null) => {
    //     if (!value) return value;

    //     const { learningGroups } = value;

    //     if (learningGroups.length === 0) {
    //       setIsAllGroupsFetched(true);
    //     }

    //     setCanFetchMore(true);

    //     return value;
    //   }
    // );
  }, [api, endDate, fetchGroups, sampleByStatus, startDate, status]);

  useEffect(() => {
    setTablePage(1);
  }, [
    startDate,
    endDate,
    companyId,
    groupId,
    teacherId,
    status,
    sampleByStatus,
    onlyLessonsWithoutGroup,
  ]);

  useEffect(() => {
    fetchCompanies((token) => api.getCustomerCompanies(token));
  }, [api, fetchCompanies]);

  // Синхронизация с url параметрами
  useEffect(() => {
    syncSearchParams(data);
  }, [data, syncSearchParams]);

  // autorun(() => {
  //   if (
  //     filteredLessons.length <= COUNT &&
  //     canFetchMore &&
  //     !isAllGroupsFetched
  //   ) {
  //     setCanFetchMore(false);
  //     setGroupsPage((p) => p + 1);
  //   }
  // });

  const showTable = sampleByStatus
    ? startDate && endDate
    : companyId || groupId || status || teacherId;

  return (
    <ThemeProvider theme={defaultTheme}>
      <I18nextProvider i18n={i18n}>
        <Loading loading={loading}>
          <ContentContainer>
            <BlockContainer>
              <Title />

              <Box sx={{ mb: 4 }} />

              <LessonFilter
                methods={methods}
                lessonsToDownload={filteredLessons}
                showTeacherFilter={isDistributorOrCoordinator}
                showAdditionalFilters={isDistributorOrCoordinator}
              />

              <Loading loading={isLessonsLoading} error={groupsError}>
                {showTable ? (
                  <LessonTable
                    lessons={filteredLessons}
                    count={LESSONS_PER_PAGE}
                    page={tablePage}
                    onChangePage={handleChangeTablePage}
                    showAttendanceReport={showAttendanceReport}
                    showGroupReport={showGroupReport}
                  />
                ) : (
                  <Helper sampleByStatus={sampleByStatus} />
                )}
              </Loading>
            </BlockContainer>
          </ContentContainer>
        </Loading>
      </I18nextProvider>

      <CssBaseline />
    </ThemeProvider>
  );
});
