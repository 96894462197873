import { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";

import { Loading } from "components/Loading";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";

import { TeacherListModules } from "./components/ListModules";
import { TeacherMyClasses } from "./components/MyClasses";
import { PageBlock } from "./components/PageBlock";

export const MainTeacher = observer(() => {
  const store = useStores();

  const { t } = useTranslation();

  const { course: courseStore, learningGroup: learningGroupStore, api } = store;

  const { items: courses } = courseStore;
  const { items: groups } = learningGroupStore;

  const {
    fetch: fetchCourses,
    loading: loadingCourses,
    error: coursesError,
  } = useCancelableFetch();

  const {
    fetch: fetchGroups,
    loading: loadingGroups,
    error: groupsError,
  } = useCancelableFetch();

  const isLoading = loadingCourses || loadingGroups;
  const isError = Boolean(coursesError || groupsError);

  const activeCourses = courses.filter((course) => {
    return !["filling", "archived", "disbanded"].includes(
      course.groupStatus ?? ""
    );
  });

  useEffect(() => {
    fetchCourses((token) => api.getCourses(token));
  }, [api, fetchCourses]);

  useEffect(() => {
    const filters = { includeLessons: true };
    fetchGroups((token) => api.getLearningGroups(filters, token));
  }, [api, fetchGroups]);

  return (
    <WrapperMainStudents>
      <Loading loading={isLoading} error={isError}>
        <TeacherMyClasses />
        <PageBlock title={t(`StartPageStudent:myModules`)}>
          <TeacherListModules
            countPage={2}
            courses={activeCourses}
            groups={groups}
          />
        </PageBlock>

        <Outlet />
      </Loading>
    </WrapperMainStudents>
  );
});
