/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotOut } from "mobx-state-tree";

import { DistributorReportType } from "../../services/Api";
import { withEnvironment } from "../extensions/withEnvironment";

export const GenerateDistributorReportModel = types
  .model({
    id: types.identifier,
    name: types.maybeNull(types.string),
    dateFrom: types.maybeNull(types.string),
    dateTo: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.Date),
    sendToEmailPath: types.maybeNull(types.string),
    downloadUrl: types.maybeNull(types.string),
    xlsxPath: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    downloadXlsx() {
      let url = self.xlsxPath ?? "";
      if (!url) {
        return false;
      }

      if (self.type === DistributorReportType.teachers) {
        url += "?type=short";
      }

      window.open(url);

      return true;
    },
  }));

type GenerateDistributorReportModelType = Instance<
  typeof GenerateDistributorReportModel
>;

export interface GenerateDistributorReport
  extends GenerateDistributorReportModelType {}
type GenerateDistributorReportSnapshotType = SnapshotOut<
  typeof GenerateDistributorReportModel
>;
export type GenerateDistributorReportSnapshot =
  GenerateDistributorReportSnapshotType;
