import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import uniq from "lodash/uniq";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";
import { useCancelableFetch } from "@packages/store/hooks";
import {
  GetLearningGroups,
  GetLearningGroupsParams,
} from "@packages/store/services/Api";

import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";

import {
  AllPayrollGroupsForm,
  AllPayrollGroupsFormValues,
} from "./components/AllPayrollGroupsForm";
import {
  ModalPayrollGroup,
  ModalPayrollGroupFormValues,
} from "../PayrollAccountant/components/ModalPayrollGroup/ModalPayrollGroup";
import { PayrollGroupsTable } from "../PayrollAccountant/components/PayrollGroupsTable";
import {
  filterByClassGroups,
  filterByTeacher,
  filterByGroupName,
} from "../PayrollAccountant/helpers/filterGroups";

const GROUPS_LIMIT = 100;

export const AllPayrollGroups = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const { api, learningGroup, learningGroupApprove, surcharge } = useStores();

  const [page, setPage] = useState(1);
  const [allGroupsFetched, setAllGroupsFetched] = useState(false);
  const [paginationPage, setPaginationPage] = useState(0);
  const [formData, setFormData] = useState<AllPayrollGroupsFormValues | null>(
    null
  );

  const [isGroupModalOpen, setGroupModalOpen] = useState(false);
  const openGroupModal = () => setGroupModalOpen(true);
  const closeGroupModal = () => setGroupModalOpen(false);

  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const selectedGroup = selectedGroupId
    ? learningGroup.getById(selectedGroupId)
    : undefined;

  const { fetch: fetchGroups, loading: loadingGroups } = useCancelableFetch();
  const { fetch: fetchGroupSurcharges, loading: loadingGroupSurcharges } =
    useCancelableFetch();
  const { fetch: fetchTeacherRates, loading: loadingTeacherRates } =
    useCancelableFetch();
  const { fetch: fetchTeacherRates2, loading: loadingTeacherRates2 } =
    useCancelableFetch();
  const { fetch: fetchClassGroups, loading: loadingClassGroups } =
    useCancelableFetch();
  const { fetch: fetchLanguages, loading: loadingLanguages } =
    useCancelableFetch();
  const { fetch: createSurcharge } = useCancelableFetch();
  const { fetch: approveGroup } = useCancelableFetch();

  const loading =
    loadingGroups ||
    loadingGroupSurcharges ||
    loadingTeacherRates ||
    loadingTeacherRates2 ||
    loadingClassGroups ||
    loadingLanguages;

  const filteredPayrollGroups = computed(() =>
    learningGroup.items
      .filter(filterByTeacher)
      .filter(filterByClassGroups)
      .filter(filterByGroupName(formData?.group ?? ""))
  ).get();

  const handleApprove = async (groupId: string) => {
    await approveGroup(() => api.approveLearningGroup(groupId));
  };

  // eslint-disable-next-line consistent-return
  const fetchLearningGroups = async (filters: GetLearningGroupsParams) => {
    const groups: GetLearningGroups = await fetchGroups((token) =>
      api.getLearningGroups(filters, token)
    );

    if (groups && groups.kind === "ok") {
      if (groups.learningGroups.length === 0) {
        return setAllGroupsFetched(true);
      }

      const groupIds = groups.learningGroups.map((group) => group.id);
      const classGroupIds = groups.learningGroups
        .map((group: any) =>
          group.learningCourses.map((course: any) => course.classGroupIds)
        )
        .flat(2);

      const teacherIds = uniq(
        groups.learningGroups
          .map(
            (group) =>
              group.teachers.find((teacher) => teacher.excludedAt === null)
                ?.actualId
          )
          .filter(Boolean) as string[]
      );

      await Promise.all([
        fetchGroupSurcharges((token) =>
          api.getLearningGroupSurcharges({ groupIds }, token)
        ),
        fetchClassGroups((token) =>
          api.getClassGroups({ groupIds: classGroupIds }, token)
        ),
        fetchTeacherRates((token) =>
          api.getTeacherRates(
            {
              teacherId: teacherIds,
              classType: "webinar",
              limit: teacherIds.length * 2,
            },
            token
          )
        ),
        fetchTeacherRates2((token) =>
          api.getTeacherRates(
            {
              teacherId: teacherIds,
              classType: "face2face",
              limit: teacherIds.length * 2,
            },
            token
          )
        ),
      ]);
    }
  };

  const handleChangePage = (newPage: number) => {
    const maximumPages = Math.ceil(filteredPayrollGroups.length / 10);

    // Дозапрашиваем ещё группы если попали на последнюю страницу
    if (newPage === maximumPages - 1 && !allGroupsFetched && formData) {
      const filters: GetLearningGroupsParams = {
        page: page + 1,
        limit: GROUPS_LIMIT,
        status: "learning",
        teacher: formData.teacherId ? formData.teacherId : undefined,
        name: formData.group ? formData.group : undefined,
      };

      fetchLearningGroups(filters);

      setPage((p) => p + 1);
    }

    setPaginationPage(newPage);
  };

  const handleRequestGroupEdit = (groupId: string) => {
    setSelectedGroupId(groupId);
    openGroupModal();
  };

  const handleSubmit = async ({
    surcharge: newSurcharges,
  }: ModalPayrollGroupFormValues) => {
    if (!selectedGroupId) return;

    const currentGroupSurcharges = surcharge.getByGroupId(selectedGroupId);

    const surchargesToDelete = currentGroupSurcharges.filter(
      (s) => !newSurcharges.some((ns) => ns.id === s.id)
    );
    const surchargesToCreate = newSurcharges.filter((s) => !s.id);
    const surchargesToUpdate = newSurcharges.filter((s) =>
      currentGroupSurcharges.some((ns) => ns.id === s.id)
    );

    const surchargesToDeleteRequests = surchargesToDelete.map((s) =>
      surcharge.deleteById(s.id)
    );
    const surchargesToUpdateRequests = surchargesToUpdate.map(
      ({ id, ...other }) =>
        surcharge.updateById(id as string, {
          ...other,
          groupId: selectedGroupId,
        })
    );
    const surchargesToCreateRequests = surchargesToCreate.map((s) =>
      createSurcharge(() =>
        api.createSurcharge({ groupId: selectedGroupId, ...s })
      )
    );

    await Promise.all([
      ...surchargesToDeleteRequests,
      ...surchargesToUpdateRequests,
      ...surchargesToCreateRequests,
    ]);

    if (!learningGroupApprove.isApproved(selectedGroupId)) {
      await handleApprove(selectedGroupId);
    }

    setSelectedGroupId(null);
    closeGroupModal();
  };

  const handleFiltersSubmit = async (data: AllPayrollGroupsFormValues) => {
    setPage(1);
    setPaginationPage(0);
    setAllGroupsFetched(false);
    setFormData(data);

    const filters: GetLearningGroupsParams = {
      page: 1,
      limit: GROUPS_LIMIT,
      status: "learning",
      teacher: data.teacherId ? data.teacherId : undefined,
      name: data.group ? data.group : undefined,
    };

    fetchLearningGroups(filters);
  };

  useEffect(() => {
    fetchLanguages((token) => api.getLanguages(token));
  }, [api, fetchLanguages]);

  return (
    <WrapperMainStudents>
      <PageBlock title={t("Payroll:Groups")}>
        <Box sx={{ margin: "1.5rem" }} />
        <AllPayrollGroupsForm onSubmit={handleFiltersSubmit} />

        <Loading loading={loading}>
          <PayrollGroupsTable
            groups={filteredPayrollGroups}
            page={paginationPage}
            onChangePage={handleChangePage}
            onRequestGroupEdit={handleRequestGroupEdit}
          />
          <ModalPayrollGroup
            open={isGroupModalOpen}
            onClose={closeGroupModal}
            onSubmit={handleSubmit}
            group={selectedGroup}
          />
        </Loading>
      </PageBlock>
    </WrapperMainStudents>
  );
});
