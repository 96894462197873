const ar = {
  Groups: {
    title: "المجموعات",
  },
  GroupList: {
    group: "المجموعة",
    dates: "فترة الصفوف",
    report: "تقرير الصفوف",
    statistics: "إحصائيات المنصة",
    progress: "تقدم المنصة",
    lessonsCount: "الصفوف المنعقدة",
    total: "إجمالي الساعات",
    finished: "الساعات المكتملة",
    left: "الساعات المتبقية",
    period: "الفترة",
    teachers: "المعلمون",
    attendance: "الحضور",
  },
  ModalGroupReport: {
    Download: "تحميل",
    Teacher: "المعلم",
    Course: "الدورة",
    Schedule: "الجدول",
    Hours: "ساعات أكاديمية",
    TotalHours: "إجمالي الساعات",
    CompletedHours: "الساعات المكتملة",
    LeftHours: "الساعات المتبقية",
    Compensation: "تعويض",
    Students: "الطلاب",
    Duration: "المدة",
  },
  GroupFilter: {
    Company: "الشركة",
    ActiveGroups: "المجموعات النشطة",
    ActiveGroupsNo: "لا",
    ActiveGroupsYes: "نعم",
    SelectLevel: "اختر المستوى",
    SearchStudent: "ابحث عن طالب",
    SearchGroup: "ابحث عن مجموعة",
    SelectModule: "اختر الوحدة",
    AllModules: "جميع الوحدات",
    StartDate: "تاريخ البدء",
    EndDate: "تاريخ الانتهاء",
    WithLessonsWithoutTeachers: "بدون معلم",
    WithLessonsOnlyTechnicalCancellationBefore: "الإلغاءات",
  },
  GroupDownload: {
    Download: "تحميل",
    Groups: "المجموعات",
    WithoutPrice: "المجموعات بدون سعر",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "أدخل اسم المجموعة",
    SelectGroup: "اختر مجموعة",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "لا توجد مجموعات لك.",
    SecondTextDefault: "يرجى الاتصال بمنسق دراستك.",
    FirstTextDistributor: "لا توجد بيانات لعرضها.",
    SecondTextDistributor: "حاول إدخال بعض البيانات.",
    FirstTextClasses: "لا توجد بيانات لعرضها.",
    SecondTextClasses: "حاول تغيير المرشحات أو الاتصال بالدعم.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "لم يتم العثور على معلمين",
    SelectTeacher: "اختر معلمًا",
  },
  ShortWeekDay: {
    "0": "الأحد",
    "1": "الإثنين",
    "2": "الثلاثاء",
    "3": "الأربعاء",
    "4": "الخميس",
    "5": "الجمعة",
    "6": "السبت",
  },
  LessonStatuses: {
    completed: "أُجرِي",
    planned: "المخطط لها",
    earlyCanceledByCustomer: "الإلغاء المبكر من قبل المستمعين",
    earlyCanceledBySchool: "الإلغاء المبكر من قبل المعلم",
    lateCanceledByCustomer: "الإلغاء المتأخر من قبل المستمعين",
    lateCanceledBySchool: "الإلغاء المتأخر من قبل المعلم",
    notPerformedToPay: "للدفع",
  },
};

export default ar;
