import { Typography, FormLabel } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";

import { Checkbox } from "components/Checkbox";

export const SettingsView = observer((): JSX.Element | null => {
  const { auth } = useStores();
  const { t } = useTranslation();

  const checked = !auth.dontShowExercisesResults;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    auth.setDontShowExercisesResults(!e.target.checked);
  };

  return (
    <FormLabel sx={{ cursor: "pointer" }}>
      <Typography variant="h3" component="span" color="custom.grey.dark">
        {t("Profile:ShowPassingResults")}
      </Typography>
      <Checkbox checked={checked} onChange={handleChange} />
    </FormLabel>
  );
});
