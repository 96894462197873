import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface HelperProps {
  sampleByStatus: boolean;
}

export const Helper = ({ sampleByStatus }: HelperProps) => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">
      {sampleByStatus
        ? t("Classes:ProcessHelper")
        : t("Classes:StandardHelper")}
    </Typography>
  );
};
