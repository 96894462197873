/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Instance,
  SnapshotOut,
  types,
  cast,
  resolveIdentifier,
  getRoot,
} from "mobx-state-tree";

import { UnitModel, UnitSnapshot, Unit } from "./UnitModel";
import { withEnvironment } from "../extensions/withEnvironment";
import { RootStore } from "../RootStore/RootStore";

export const UnitStoreModel = types
  .model("UnitStore")
  .props({
    items: types.optional(types.array(UnitModel), []),
    loading: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value;
    },
  }))
  .actions((self) => ({
    addItem: (item: UnitSnapshot) => {
      const instance = resolveIdentifier(UnitModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .views((self) => ({
    getUnit(id: string | undefined): Unit | undefined {
      return self.items.find((item) => item.id === id);
    },
  }))
  .actions((self) => ({
    async loadUnit(id: string) {
      const rootStore = getRoot<RootStore>(self);
      self.setLoading(true);
      await rootStore.getUnit_v3(id, true);
      self.setLoading(false);
    },
  }));

type UnitStoreType = Instance<typeof UnitStoreModel>;
export type UnitStore = UnitStoreType;
type UnitStoreSnapshotType = SnapshotOut<typeof UnitStoreModel>;
export type UnitStoreSnapshot = UnitStoreSnapshotType;
export const createUnitStoreDefaultModel = () =>
  types.optional(UnitStoreModel, {} as UnitStoreSnapshot);
