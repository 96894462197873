import { useTheme } from "@mui/material";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { CompanyReport } from "@packages/store/models/CompanyReport/CompanyReport";

import { Pagination } from "components/Pagination";
import { Table } from "components/Table";
import { TableCell } from "pages/shared/styledComponents";

import {
  formatCompanyReport,
  FormattedCompanyReport,
} from "../helpers/formatCompanyReport";

interface CompaniesReportTableProps {
  reports: CompanyReport[];

  totalCompanies: number;
  count: number;
  page: number;
  onChangePage: (page: number) => void;
}

export const CompaniesReportTable = observer(
  ({
    reports,
    totalCompanies,
    count,
    page,
    onChangePage,
  }: CompaniesReportTableProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();

    const columns = [
      {
        Header: t("CompaniesReport:TableCompanyName"),
        accessor: "name",
        Cell: ({ value }: Cell<FormattedCompanyReport, string>) => (
          <TableCell>{value}</TableCell>
        ),
        style: {
          backgroundColor: theme.palette.custom.grey["menu-tile-inactive"],
        },
      },
      {
        Header: t("CompaniesReport:TableGroup"),
        accessor: "totalGroups",
        Cell: ({ value }: Cell<FormattedCompanyReport, string>) => (
          <TableCell>{value}</TableCell>
        ),
      },
      {
        Header: t("CompaniesReport:TableLessons"),
        accessor: "totalLessons",
        Cell: ({ value }: Cell<FormattedCompanyReport, string>) => (
          <TableCell>{value}</TableCell>
        ),
      },
      {
        Header: t("CompaniesReport:TableAcademicHours"),
        accessor: "totalAcademicHours",
        Cell: ({ value }: Cell<FormattedCompanyReport, string>) => (
          <TableCell>{Number(value).toFixed(2)}</TableCell>
        ),
      },
      {
        Header: t("CompaniesReport:TablePayment"),
        accessor: "totalPayment",
        Cell: ({ value }: Cell<FormattedCompanyReport, string>) => (
          <TableCell>{Number(value).toFixed(1)}</TableCell>
        ),
      },
      {
        Header: t("CompaniesReport:TablePrice"),
        accessor: "averagePrice",
        Cell: ({ value }: Cell<FormattedCompanyReport, string>) => (
          <TableCell>{Number(value).toFixed(1)}</TableCell>
        ),
      },
    ];

    const formattedReports = computed(() =>
      reports.map((report) => formatCompanyReport(report))
    ).get();

    return (
      <>
        <Table
          columns={columns}
          data={formattedReports}
          containerType="blue"
          emptyTableType="distributor"
          count={count}
        />
        <Pagination
          page={page + 1}
          dataLength={totalCompanies}
          pageSize={count}
          onChange={(_, p) => onChangePage(p - 1)}
        />
      </>
    );
  }
);
