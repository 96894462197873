import { useState } from "react";

import { Alert, Box, Snackbar } from "@mui/material";
import { observer } from "mobx-react-lite";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";

import { Button } from "components/Button";

import { InputField } from "./InputField";

interface PasswordFormFields {
  password: string;
  passwordRepeat: string;
}

export const ChangePasswordView = observer((): JSX.Element | null => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<PasswordFormFields>();

  const { auth } = useStores();

  const { changePassword } = auth;
  const [successMessage, setSuccessMessage] = useState("");

  const { t } = useTranslation();

  const hideSuccessMessage = () => {
    setSuccessMessage("");
  };

  const onSubmit: SubmitHandler<PasswordFormFields> = async ({
    password,
    passwordRepeat,
  }) => {
    if (password.length < 6) {
      setError("password", {
        message: t(`ChangePassword:PasswordShortError`) ?? "",
      });
      return;
    }
    if (password !== passwordRepeat) {
      setError("passwordRepeat", {
        message: t(`ChangePassword:PasswordMismatch`) ?? "",
      });
      return;
    }
    const passwordChanged = await changePassword(password);
    if (!passwordChanged) {
      return;
    }
    setValue("password", "");
    setValue("passwordRepeat", "");
    setSuccessMessage(t("ChangePassword:PasswordChangeSuccess") ?? "");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gap="1rem" maxWidth="370px">
        <InputField
          label={t("Profile:NewPassword")}
          {...register("password", { required: true })}
          required
          error={!!errors.password}
          helperText={errors.password?.message || " "}
        />
        <InputField
          label={t("Profile:RepeatPassword")}
          {...register("passwordRepeat", { required: true })}
          required
          error={!!errors.passwordRepeat}
          helperText={errors.passwordRepeat?.message || " "}
        />
        <Button type="submit" variant="primary">
          {t("Profile:Change")}
        </Button>
      </Box>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={hideSuccessMessage}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={hideSuccessMessage}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </form>
  );
});
