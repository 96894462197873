const es = {
  AttendancePivotTable: {
    Student: "Estudiante",
    Visited: "Asistido, %",
    Hours: "Cantidad de horas académicas",
    Month: "Mes",
    Lesson: "Lección",
    Date: "Fecha",
    Duration: "Duración",
    Teacher: "Profesor",
    Days: "Días",
    Time: "Hora",
    Course: "Curso: {{course, string}}",
    GroupSchedule: "Horario de clases del grupo {{group, string}}",
    Total: "Programa {{hours, number}} horas académicas",
    CompletedPeriod:
      "Realizado durante el período {{hours, number}} horas académicas",
    LeftPeriod:
      "Quedan al final del período {{hours, number}} horas académicas",
    NotPerformedToPayPeriod:
      "Cancelaciones superiores a la norma {{hours, number}} horas académicas",
    TotalInTable: "Programa",
    CompletedInTable: "Realizado durante el período",
    NotPerformedToPayHours: "Cancelaciones superiores a la norma: ",
    LeftInTable: "Quedan al final del período",
    MinHours: "horas académicas",
  },
  ReportsAttendance: {
    Title: "Asistencia",
    AllGroups: "Todos los grupos",
    SelectGroup: "Seleccionar grupo",
    EmptyGroupAndCompany: "Seleccione una empresa o grupo para ver el informe",
    Download: "Descargar",
    AllCompanies: "Todas las empresas",
    SelectCompany: "Seleccionar empresa",
    EmptyLessons:
      "Actualmente no tiene clases completadas según los filtros seleccionados, por lo que no se puede generar un informe de asistencia",
    OnlyTeachersLessons: "Solo mis clases",
  },
  AttendancePivotReportDownload: {
    Sheet: "Informe resumido",
  },
  DatePicker: {
    Today: "Hoy",
    Clear: "Borrar",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Ingrese el nombre del grupo",
    SelectGroup: "Seleccionar grupo",
  },
  Loading: {
    Error: "Error de carga, intente actualizar la página",
  },
  ModalGroupReport: {
    Teacher: "Profesor",
    Course: "Curso",
    Schedule: "Horario",
    Duration: "Duración",
  },
  GroupFilter: {
    SearchStudent: "Buscar estudiante",
    StartDate: "Fecha de inicio",
    EndDate: "Fecha de finalización",
  },
  MonthName: {
    "1": "Enero",
    "2": "Febrero",
    "3": "Marzo",
    "4": "Abril",
    "5": "Mayo",
    "6": "Junio",
    "7": "Julio",
    "8": "Agosto",
    "9": "Septiembre",
    "10": "Octubre",
    "11": "Noviembre",
    "12": "Diciembre",
  },
  ShortWeekDay: {
    "0": "DO",
    "1": "LU",
    "2": "MA",
    "3": "MI",
    "4": "JU",
    "5": "VI",
    "6": "SA",
  },
  Progress: {
    totalProgress: "Progreso total",
    completed: "Completado",
    yourProgress: "Tu progreso:",
  },
};

export default es;
