const en = {
  AttendancePivotTable: {
    Student: "Student",
    Visited: "Visited, %",
    Hours: "Hours count",
    Month: "Month",
    Lesson: "Lesson",
    Date: "Date",
    Duration: "Duration",
    Teacher: "Teacher",
    Days: "Days",
    Time: "Time",
    Course: "Course {{course, string}}",
    GroupSchedule: "Lesson schedule in the {{group, string}} group",
    Total: "Program {{hours, number}} hours",
    CompletedPeriod: "Completed in period {{hours, number}} hours",
    LeftPeriod: "Remaining at the end of period {{hours, number}} hours",
    NotPerformedToPayPeriod: "Excess cancellations {{hours, number}} hours",
    TotalInTable: "Program",
    CompletedInTable: "Completed in period",
    NotPerformedToPayHours: "Excess cancellations: ",
    LeftInTable: "Remaining at the end of period",
    MinHours: "ac.h.",
  },
  ReportsAttendance: {
    Title: "Attendance",
    SelectGroup: "Group select",
    AllGroups: "All groups",
    EmptyGroupAndCompany: "Select company or group to see report",
    Download: "Download",
    AllCompanies: "All companies",
    SelectCompany: "Select company",
    EmptyLessons:
      "At the moment, you do not have completed classes for the selected filters, and therefore it is impossible to build a report on attendance",
    OnlyTeachersLessons: "Only my classes",
  },
  AttendancePivotReportDownload: {
    Sheet: "Pivot report",
  },
  DatePicker: {
    Today: "Today",
    Clear: "Clear",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Enter group name",
    SelectGroup: "Group select",
  },
  Loading: {
    Error: "Loading error, try refreshing the page",
  },
  ModalGroupReport: {
    Teacher: "Teacher",
    Course: "Course",
    Schedule: "Schedule",
    Duration: "Duration",
  },
  GroupFilter: {
    SearchStudent: "Search student",
    StartDate: "Start date",
    EndDate: "End date",
  },
  MonthName: {
    "1": "January",
    "2": "February",
    "3": "March",
    "4": "April",
    "5": "May",
    "6": "June",
    "7": "July",
    "8": "August",
    "9": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  },
  ShortWeekDay: {
    "0": "Sun.",
    "1": "Mon.",
    "2": "Tue.",
    "3": "Wed.",
    "4": "Thu.",
    "5": "Fri.",
    "6": "Sat.",
  },
  Progress: {
    totalProgress: "Total Progress",
    completed: "completed",
    yourProgress: "Your Progress:",
  },
};

export default en;
