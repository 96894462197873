import { useEffect, useRef } from "react";

import { useLocation } from "react-router-dom";

import { useStores } from "@packages/store/models";

import { ROUTES } from "router/constants";

import { Timer } from "./Timer";

export function ActivityTracker(): JSX.Element {
  const { auth, sendUserActive, sendUserVisit } = useStores();
  const courseId = useRef<number>(0);

  const { isSingIn = false } = auth;

  const { pathname } = useLocation();

  const onUserVisit = () => {
    sendUserVisit();
  };

  const onUserActive = (times: number) => {
    sendUserActive(times, courseId.current);
  };

  useEffect(() => {
    const courseData = pathname.match(
      new RegExp(`${ROUTES.MY_COURSES}\\/([0-9]+)`)
    );

    courseId.current = Number(courseData?.[1] ?? 0);
  }, [pathname, courseId]);

  if (!isSingIn) {
    return <></>;
  }

  return <Timer onVisit={onUserVisit} onPing={onUserActive} />;
}
