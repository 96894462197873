import { Box, ThemeProvider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { I18nextProvider, useTranslation } from "react-i18next";

import { defaultTheme } from "@packages/shared/themes/default";
import { useStores } from "@packages/store/models";

import { ModalGroupReport } from "../components/ModalGroupReport";
import { getGroupClassPeriodDates } from "../helpers";
import i18n from "../locales/i18n";

interface GroupReportProps {
  id: string | undefined;
  onClose: () => void;
}

export const GroupReport = observer(
  ({ id, onClose }: GroupReportProps): JSX.Element => {
    const { t } = useTranslation();

    const store = useStores();

    const { learningGroup } = store;

    const group = learningGroup.items.find((item) => item.id === id);

    if (!group) {
      return <Box />;
    }

    const { statistics } = group;

    const getWeekDay = (day: string) => {
      return t(`ShortWeekDay:${day}`);
    };

    const schedules = statistics.schedules.map((schedule) => {
      const { day, startTime, endTime } = schedule;
      const weekDay = getWeekDay(day);
      return `${weekDay} ${startTime} - ${endTime}`;
    });

    return (
      <ThemeProvider theme={defaultTheme}>
        <I18nextProvider i18n={i18n}>
          <ModalGroupReport
            isOpen
            onClose={onClose}
            groupName={group.name ?? ""}
            teacherName={statistics.teacherName}
            schedule={schedules}
            courseName={statistics.courses}
            classPeriod={getGroupClassPeriodDates(group)}
            totalHours={statistics.totalHours}
            completedHours={statistics.completedHours}
            leftHours={statistics.leftHours}
            lateCustomerCancelHours={statistics.lateCustomerCancelHours}
            lateSchoolCancelHours={statistics.lateSchoolCancelHours}
            compensationHours={statistics.compensationHours}
            earlyCustomerCancelHours={statistics.earlyCustomerCancelHours}
            earlySchoolCancelHours={statistics.earlySchoolCancelHours}
            students={statistics.students}
          />
        </I18nextProvider>
      </ThemeProvider>
    );
  }
);
