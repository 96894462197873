import { useState } from "react";

import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";

import { Word, WordsList } from "components/WordsList";
import { MyWordsDictionary } from "pages/Student/MyWords/components/MyWordsDictionary";
import { getFilteredWords } from "pages/Student/MyWords/helpers";

export const MyWordsList = observer((): JSX.Element => {
  const { t } = useTranslation();

  const { word: wordStore, wordFilter } = useStores();
  const [translatedList, setTranslatedList] = useState<string[]>([]);
  const [dictionaryWordId, setDictionaryWordId] = useState("");

  const words = wordStore.items;

  const filteredWords = getFilteredWords(words, wordFilter);

  const sortedWords = filteredWords.sort((prevWord, nextWord) => {
    const prevHeader = (prevWord.header ?? "").toLowerCase();
    const nextHeader = (nextWord.header ?? "").toLowerCase();
    return prevHeader > nextHeader ? 1 : -1;
  });

  const formattedWords = sortedWords.map((word) => ({
    id: word.id ?? "",
    word: word.header ?? "",
    translation: word.translation ?? "",
    learned: word.isLearned ?? false,
    translated: translatedList.includes(word.id ?? ""),
  }));

  const getSourceWord = (id: string) => {
    return words.find((word) => word.id === id);
  };

  const playRemoteVoice = async (path: string) => {
    const audio = new Audio(path);
    try {
      await audio.play();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Error remote voice playing");
    }
  };

  const handleVoice = (word: Word) => {
    if (!word.translated) {
      const sourceWord = getSourceWord(word.id);
      const pathOfSpeech = sourceWord?.pathOfSpeech;
      if (pathOfSpeech) {
        playRemoteVoice(pathOfSpeech);
        return;
      }
    }
    const text = word.translated ? word.translation : word.word;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = word.translated ? "ru" : "en";
    const voices = window.speechSynthesis.getVoices();
    const voiceLanguage = word.translated ? "ru-RU" : "en-US";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const voice = voices.find((voice) => voice.lang === voiceLanguage);
    if (voice) {
      utterance.voice = voice;
    }
    speechSynthesis.speak(utterance);
  };

  const handleLanguage = (word: Word) => {
    if (word.translated) {
      setTranslatedList(translatedList.filter((id) => word.id !== id));
    } else {
      setTranslatedList([...translatedList, word.id]);
    }
  };

  const handleBookmark = (word: Word) => {
    setDictionaryWordId(word.id);
  };

  const handleTrash = (word: Word) => {
    const sourceWord = getSourceWord(word.id);
    sourceWord?.markAsLearned();
  };

  const closeDictionary = () => {
    setDictionaryWordId("");
  };

  return (
    <Box display="flex" gap="1rem" flexWrap="wrap" width="100%">
      {filteredWords.length > 0 && (
        <>
          <WordsList
            words={formattedWords}
            onVoice={handleVoice}
            onLanguage={handleLanguage}
            onBookmark={handleBookmark}
            onTrash={handleTrash}
          />
          <MyWordsDictionary
            wordId={dictionaryWordId}
            onClose={closeDictionary}
          />
        </>
      )}
      {filteredWords.length === 0 && (
        <Typography>{t("MyWords:EmptyWordList")}</Typography>
      )}
    </Box>
  );
});
