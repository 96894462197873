import { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";

import { Loading } from "components/Loading";
import { StickySection } from "components/StickySection";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";

import {
  MyWordsFilter,
  MyWordsProgress,
  MyWordsList,
  LearnWords,
} from "./components";
import {
  DesktopContainer,
  FilterContainer,
  MyWordsContainer,
  TabletContainer,
} from "./styledComponents";

export const MyWords = observer((): JSX.Element => {
  const { t } = useTranslation();

  const { api, loading } = useStores();
  const {
    fetch: fetchWords,
    loading: loadingWords,
    error: wordsError,
  } = useCancelableFetch();
  const {
    fetch: fetchWordsFilter,
    loading: loadingWordFilter,
    error: wordFilterError,
  } = useCancelableFetch();

  const isLoading = loading || loadingWords || loadingWordFilter;
  const isError = Boolean(wordsError || wordFilterError);

  useEffect(() => {
    fetchWords((token) => api.getWords(token));
  }, [fetchWords, api]);

  useEffect(() => {
    fetchWordsFilter((token) => api.getWordFilter(token));
  }, [fetchWordsFilter, api]);

  return (
    <WrapperMainStudents display="flex" flexDirection="column" flex={1}>
      <Typography variant="h1" my="2rem">
        {t("MyWords:Title")}
      </Typography>

      <Loading loading={isLoading} error={isError}>
        <MyWordsProgress />
        <Box>
          <MyWordsContainer flex={1}>
            <Box mt="1rem" pt="1rem">
              <StickySection top={5}>
                <FilterContainer>
                  <MyWordsFilter />

                  <TabletContainer>
                    <LearnWords />
                  </TabletContainer>
                </FilterContainer>
              </StickySection>
            </Box>
            <Box flex={1}>
              <DesktopContainer>
                <LearnWords />
              </DesktopContainer>
              <MyWordsList />
            </Box>
          </MyWordsContainer>
        </Box>
      </Loading>
    </WrapperMainStudents>
  );
});
