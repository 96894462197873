import { useEffect } from "react";

import { computed } from "mobx";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";
import { useCancelableFetch } from "@packages/store/hooks";

import { Autocomplete } from "components/Autocomplete";
import { ModalAttendanceFormValues } from "components/ModalAttendance";
import { ValueWithLabel } from "types";

interface UnitNumberProps {
  control: Control<ModalAttendanceFormValues>;
  disabled?: boolean;
  courseId?: string;
}

export const UnitNumber = ({
  control,
  disabled = false,
  courseId,
}: UnitNumberProps): JSX.Element | null => {
  const { t } = useTranslation();

  const { api, course: courseStore } = useStores();

  const {
    field: { value, onChange },
  } = useController({
    control,
    name: "actualUnitId",
  });

  const { fetch: fetchUnits } = useCancelableFetch();

  const options: ValueWithLabel[] = computed(() => {
    const course = courseStore.getCourse(courseId);

    if (!course) return [];

    return course.sortedUnits.map((unit) => ({
      value: unit.order?.toString() ?? "",
      label: `${t("ModalAttendance:UnitLabel")} ${unit.order}. ${
        unit.nameWithoutUnit
      }`,
    }));
  }).get();

  useEffect(() => {
    if (!courseId) return;

    fetchUnits((token) => api.getUnitsByCourse(courseId, token));
  }, [fetchUnits, courseId, api]);

  return (
    <Autocomplete
      placeholder={t("ModalAttendance:UnitLabel").toString()}
      disabled={disabled}
      value={value}
      options={options}
      onChange={onChange}
    />
  );
};
