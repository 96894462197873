import { isBefore, isAfter, isEqual } from "date-fns";

import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { isBetween } from "./date";

export const filterByGroupExistence =
  (hasAGroup: boolean) => (lesson: Lesson) => {
    return Boolean(lesson.group) === hasAGroup;
  };

export const filterByCompany = (company: string) => (lesson: Lesson) => {
  return !company || company === lesson.customerCompany?.id;
};

export const filterByGroup = (group: string) => (lesson: Lesson) => {
  return !group || group === lesson?.group?.id;
};

export const filterByTeacher = (teacher: string) => (lesson: Lesson) => {
  return !teacher || lesson.teacherId === teacher;
};

export const filterByStatus = (status: string) => (lesson: Lesson) => {
  return !status || status === lesson.status;
};

export const filterByDateRange =
  (startDate: Date | null, endDate: Date | null) => (lesson: Lesson) => {
    if (!lesson.startAtLocal) {
      return true;
    }

    const date = lesson.startAtLocal;

    if (!startDate && endDate) {
      return isEqual(date, endDate) || isBefore(date, endDate);
    }

    if (startDate && !endDate) {
      return isEqual(date, startDate) || isAfter(date, startDate);
    }

    if (startDate && endDate) {
      return isBetween(date, startDate, endDate);
    }

    return true;
  };
