const tt = {
  CreativeTasks: {
    Title: "Иҗади биремнәр",
    UpdateTaskTeacher: "Сакларга",
    SubmitTaskTeacher: "Нәтиҗәләрне җибәрергә",
    UpdateTaskStudent: "Караламаны сакларга",
    SubmitTaskStudent: "Сакларга һәм тикшерүгә җибәрергә",
    AnswerHeaderStatistics:
      "Сораулар: {{questions}} | Символлар: {{symbols}} | Сүзләр: {{words}} | Җөмләләр: {{sentenses}}",
    QuestionsAndComments: "Хаталар һәм комментарийлар:",
    HoverForDisplay: "Күрсәтү өчен курсорны күчерегез:",
    CheckingHistory: "Тикшерүләр тарихы",
    CurrentVersion: "Агымдагы версия",
    TeacherComment: "Укытучыдан эшкә комментарий:",
    SaveCorrection: "Сакларга",
    CancelCorrection: "Бетерү",
    Grammar: "Грамматика",
    Lexical: "Лексика",
    Comment: "Комментарий",
    ErrorRangeFilled:
      "Билгеләнгән диапазонда хаталарның барлык төрләре кулланылган",
    SaveError: "Сакларга",
    GrammarErrorTitle: "Грамматика",
    ResultTitle: "Иҗади биремне үтәү нәтиҗәләре",
    Student: "Студент",
    Teacher: "Укытучы",
    CurrentVersionTime: "Агымдагы версия: {{time}}",
    TaskText: "Бирем",
    HasErrors: "Хаталар бар",
    WithoutErrors: "Хатасыз башкарылган",
    UpdateSavedTeacher: "Үзгәрешләрегез сакланган",
    UpdateSavedStudent: "Караламагыз сакланган",
    UpdateSubmitedTeacher: "Тикшерү нәтиҗәләре укучыга җибәрелгән",
    UpdateSubmitedStudent: "Төзәтмәләрегез укытучыга җибәрелгән",
    Checking: "Тикшерүдә",
    Done: "Үтәлгән",
    FixStudent: "Төзәтергә",
    FixTeacher: "Хаталар бар",
    CheckingTime: "тикшерү • {{time}}",
    DoneTime: "җибәрелгән • {{time}}",
    TeacherName: "Укытучы: {{name}}",
    StudentName: "Студент: {{name}}",
    AllStatuses: "Барлык статустар",
    Checked: "Тикшерелгән",
    NewCheck: "Тикшерүдә",
    Processing: "Эшкәртелә",
    StudentSearch: "Студентны эзләү",
    TeacherSearch: "Укытучыны эзләү",
    EmptyList: "Әлегә яраклы биремнәр юк",
  },
};

export default tt;
