import { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useStores } from "@packages/store";
import { useCancelableFetch } from "@packages/store/hooks";

import { DateRange, DateRangePicker } from "components/DateRangePicker";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";

import { SummaryTable } from "./components";
import { FormButton } from "./styledComponents";

const FROM_DATE_KEY = "from";
const TO_DATE_KEY = "to";

export const Summary = observer((): JSX.Element => {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const { api, summary } = useStores();
  const { clear } = summary;

  const {
    fetch: fetchSummary,
    loading: loadingSummary,
    error: summaryError,
  } = useCancelableFetch();

  const handleSummaryForm = useCallback(() => {
    const [startDate, endDate] = dateRange;
    clear();
    if (!startDate || !endDate) {
      return;
    }
    params.set(FROM_DATE_KEY, startDate.toString());
    params.set(TO_DATE_KEY, endDate.toString());
    setParams(params);
    fetchSummary((token) => api.getSummary({ startDate, endDate }, token));
  }, [fetchSummary, clear, setParams, dateRange]);

  useEffect(() => {
    const startDateValue = params.get(FROM_DATE_KEY);
    const endDateValue = params.get(TO_DATE_KEY);
    const startDate = startDateValue ? new Date(startDateValue) : null;
    const endDate = endDateValue ? new Date(endDateValue) : null;
    setDateRange([startDate, endDate]);
    if (!startDate || !endDate) {
      return;
    }
    fetchSummary((token) => api.getSummary({ startDate, endDate }, token));
  }, [setDateRange]);

  return (
    <WrapperMainStudents>
      <PageBlock title={t("Summary:Title") ?? ""}>
        <Box
          mt="2rem"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <DateRangePicker dateRange={dateRange} onChange={setDateRange} />
          <FormButton onClick={handleSummaryForm}>
            {t("Summary:Form")}
          </FormButton>
        </Box>

        <Loading loading={loadingSummary} error={summaryError}>
          <SummaryTable />
        </Loading>
      </PageBlock>
    </WrapperMainStudents>
  );
});
