const tt = {
  AttendancePivotTable: {
    Student: "Студент",
    Visited: "Дәрестә булганнар, %",
    Hours: "Ак. сәгатьләр саны",
    Month: "Ай",
    Lesson: "Дәрес",
    Date: "Көн",
    Duration: "Дәвамлылык",
    Teacher: "Укытучы",
    Days: "Көннәр",
    Time: "Вакыт",
    Course: "Курс: {{course, string}}",
    GroupSchedule: "Төркемдә дәресләр үткәрү графигы {{group, string}}",
    Total: "Программа {{hours, number}} ак. сәгать",
    CompletedPeriod: "Чор өчен ак. сәгать үткәрелде {{hours, number}}",
    LeftPeriod: "Чор ахырына ак. сәгать калды {{hours, number}}",
    NotPerformedToPayPeriod:
      "Нормадан артык ак. сәгать бетерелде {{hours, number}}",
    TotalInTable: "Программа",
    CompletedInTable: "Бу чорда үткәрелгән",
    NotPerformedToPayHours: "Нормадан тыш бетерүләр: ",
    LeftInTable: "Чор ахырына калды",
    MinHours: "ак.сәгать",
  },
  ReportsAttendance: {
    Title: "Дәрескә йөреш",
    AllGroups: "Барлык төркемнәр",
    SelectGroup: "Төркемне сайлау",
    EmptyGroupAndCompany: "Хисапны күрү өчен компанияне яки төркемне сайлагыз",
    Download: "Бушату",
    AllCompanies: "Барлык компанияләр",
    SelectCompany: "Компания сайлагыз",
    EmptyLessons:
      "Әлегә сайланган фильтрлар буенча тәмамланган дәресләрегез юк, шуңа күрә дәрескә йөреш буенча хисап төзеп булмый",
    OnlyTeachersLessons: "Минем дәресләрем генә",
  },
  AttendancePivotReportDownload: {
    Sheet: "Җыелма хисап",
  },
  DatePicker: {
    Today: "Бүген",
    Clear: "Чистартырга",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Төркем исемен языгыз",
    SelectGroup: "Төркем сайлау",
  },
  Loading: {
    Error: "Уңышсыз йөкләү, битне яңартып карагыз",
  },
  ModalGroupReport: {
    Teacher: "Укытучы",
    Course: "Курс",
    Schedule: "Расписание",
    Duration: "Дәвамлылык",
  },
  GroupFilter: {
    SearchStudent: "Студентны эзләү",
    StartDate: "Башлану көне",
    EndDate: "Тәмамлану көне",
  },
  MonthName: {
    "1": "Гыйнвар",
    "2": "Февраль",
    "3": "Март",
    "4": "Апрель",
    "5": "Май",
    "6": "Июнь",
    "7": "Июль",
    "8": "Август",
    "9": "Сентябрь",
    "10": "Октябрь",
    "11": "Ноябрь",
    "12": "Декабрь",
  },
  ShortWeekDay: {
    "0": "Як",
    "1": "Дш",
    "2": "Сш",
    "3": "Чр",
    "4": "Пн",
    "5": "Җм",
    "6": "Шм",
  },
  Progress: {
    totalProgress: "Гомуми күтәрелеш",
    completed: "Әзер",
    yourProgress: "Сезнең күтәрелеш:",
  },
};

export default tt;
