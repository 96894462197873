import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router";

import { Exercise as PackageExercise } from "@packages/exercises";

import { useQueryParam } from "hooks";

export const Exercise = observer((): JSX.Element | null => {
  const navigate = useNavigate();
  const { id: courseId, unitId } = useParams<"id" | "unitId">();

  const [blockId, setBlockId] = useQueryParam("blockId");
  const [exerciseId, setExerciseId] = useQueryParam("exerciseId");

  return (
    <Box pt="0.85rem" pb="4rem">
      <PackageExercise
        navigate={navigate}
        courseId={courseId}
        unitId={unitId}
        blockId={blockId}
        exerciseId={exerciseId}
        setBlockId={setBlockId}
        setExerciseId={setExerciseId}
        showExercisesResults
      />
    </Box>
  );
});
