import { Box, styled } from "@mui/material";

interface StyledMatchingQuestionProps {
  variant: "horizontal" | "vertical";
}

export const StyledMatchingQuestion = styled(Box, {
  shouldForwardProp: (prop) => prop !== "variant",
})<StyledMatchingQuestionProps>(({ theme, variant }) => ({
  display: "flex",
  alignItems: "center",
  flex: 1,
  gap: theme.spacing(4),
  marginBottom: "auto",
  padding: theme.spacing(3),
  border: "1px solid",
  borderColor: theme.palette.custom.grey.light,
  borderRadius: theme.spacing(3),
  minWidth: "9rem",

  ...(variant === "vertical" && {
    flexDirection: "column",
    flex: "0 1 calc(100% / 3 - 12px)",
  }),

  img: {
    objectFit: "cover",
  },

  video: {
    objectFit: "cover",
  },
}));
