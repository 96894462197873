import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import type { License as LicenseModel } from "@packages/store/models/License";

import { Icon } from "components/Icon";
import { StyledPaper } from "components/StyledPaper";

import { LicenseBody } from "./styledComponents/LicenseBody";
import { LicenseFooter } from "./styledComponents/LicenseFooter";
import { LicenseHeader } from "./styledComponents/LicenseHeader";

interface LicenseProps {
  license: LicenseModel;
}

export const License = ({ license }: LicenseProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <StyledPaper sx={{ width: "485px", overflow: "hidden" }}>
      <LicenseHeader
        sx={{
          backgroundColor:
            license.state === "activated" ? "success.main" : "#FF6161",
        }}
      >
        <Typography variant="h3">{license.licenseType?.name}</Typography>
      </LicenseHeader>
      <LicenseBody>
        <Box>
          <Typography variant="smallText" color="custom.grey.dark">
            {t("Profile:LicenseNumber")}:
          </Typography>
          <Typography variant="h3" color="custom.grey.dark">
            {license.serialNumber}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="1rem">
          <Box>
            <Typography variant="smallText" color="custom.grey.dark">
              {t("Profile:LicenseStatus")}:
            </Typography>
            <Typography
              variant="h3"
              color={license.state === "activated" ? "success.main" : "#FF6161"}
              sx={{ textTransform: "uppercase" }}
            >
              {license.state}
            </Typography>
          </Box>
          {license.state === "activated" ? (
            <Icon type="checkSquare" width="3rem" height="3rem" />
          ) : (
            <Icon type="xSquare" width="3rem" height="3rem" />
          )}
        </Box>
      </LicenseBody>
      <LicenseFooter>
        <Box>
          <Typography variant="smallText" color="custom.grey.dark">
            {t("Profile:LicenseActivated")}:
          </Typography>
          <Typography variant="h3" color="custom.grey.dark">
            {format(new Date(license.activatedAt!), "dd.MM.yyyy")}
          </Typography>
        </Box>

        {license.expiresAt && (
          <Box>
            <Typography variant="smallText" color="custom.grey.dark">
              {t("Profile:LicenseExpiresAt")}:
            </Typography>
            <Typography variant="h3" color="custom.grey.dark">
              {format(new Date(license.expiresAt), "dd.MM.yyyy")}
            </Typography>
          </Box>
        )}

        {license.remainsDays && (
          <Box>
            <Typography variant="smallText" color="custom.grey.dark">
              {t("Profile:LicenseRemainsDays")}:
            </Typography>
            <Typography
              variant="h3"
              color={license.state === "activated" ? "success.main" : "#FF6161"}
            >
              {license.remainsDays}
            </Typography>
          </Box>
        )}
      </LicenseFooter>
    </StyledPaper>
  );
};
