import { parseSearchParams } from "@packages/shared/utils/searchParams";

export const getDefaultValues = (searchParams: URLSearchParams) => {
  const data = parseSearchParams(searchParams);

  return {
    startDate: data?.startDate ?? null,
    endDate: data?.endDate ?? null,
    groupId: data?.groupId ?? "",
    studentId: data?.studentId ?? "",
    companyId: data?.companyId ?? "",
    onlyTeachersLessons: data?.onlyTeachersLessons ?? false,
  } as any;
};
