/* eslint-disable max-len */
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { StatusContainer } from "./styledComponents/StatusContainer";

export type LessonStatusesProps = {
  type: Lesson["status"];
};

export const LessonStatuses = ({ type }: LessonStatusesProps): JSX.Element => {
  const { t } = useTranslation();

  const getStatusComponent = () => {
    if (type) {
      return (
        <StatusContainer type={type}>
          {t(`LessonStatuses:${type}`)}
        </StatusContainer>
      );
    }
    return <Box>{t(`LessonStatuses:lateCanceledBySchool`)}</Box>;
  };

  return getStatusComponent();
};
