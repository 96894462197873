const ru = {
  Groups: {
    title: "Группы",
  },
  GroupList: {
    group: "Группа",
    dates: "Период занятий",
    report: "Отчеты по занятиям",
    statistics: "Статистика платформы",
    progress: "Прогресс платформы",
    lessonsCount: "Проведено занятий",
    total: "Всего ак.ч.",
    finished: "Проведено ак.ч.",
    left: "Осталось ак.ч.",
    period: "Период",
    teachers: "Преподаватели",
    attendance: "Посещаемость",
  },
  ModalGroupReport: {
    Download: "Скачать",
    Teacher: "Преподаватель",
    Course: "Курс",
    Schedule: "Расписание",
    Hours: "ак.ч.",
    TotalHours: "Всего ак.ч.",
    CompletedHours: "Проведено ак.ч.",
    LeftHours: "Осталось ак.ч.",
    Compensation: "Компенсация",
    Students: "Студенты",
    Duration: "Продолжительность",
  },
  GroupFilter: {
    ActiveGroups: "Активные группы",
    ActiveGroupsNo: "Нет",
    ActiveGroupsYes: "Да",
    SelectLevel: "Выбрать уровень",
    SearchStudent: "Поиск студента",
    SearchGroup: "Поиск группы",
    SelectModule: "Выбрать модуль",
    AllModules: "Все модули",
    AllCompanies: "Все компании",
    SelectCompany: "Выбрать компанию",
    StartDate: "Дата начала",
    EndDate: "Дата завершения",
    WithLessonsWithoutTeachers: "Без преподавателя",
    WithLessonsOnlyTechnicalCancellationBefore: "Отмены ДС",
  },
  GroupDownload: {
    Download: "Выгрузить",
    Groups: "Группы",
    WithoutPrice: "Группы без цены",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Введите название группы",
    SelectGroup: "Выбор группы",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "Вам не назначены учебные модули.",
    SecondTextDefault: "Обратитесь к вашему координатору обучения.",
    FirstTextDistributor: "Нет данных для отображения.",
    SecondTextDistributor: "Попробуйте внести какие нибудь данные.",
    FirstTextClasses: "Нет данных для отображения.",
    SecondTextClasses:
      "Попробуйте поменять фильтры или обратитесь к поддержке.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Учителя не найдены",
    SelectTeacher: "Выбор учителя",
  },
  ShortWeekDay: {
    "0": "ВС",
    "1": "ПН",
    "2": "ВТ",
    "3": "СР",
    "4": "ЧТ",
    "5": "ПТ",
    "6": "СБ",
  },
  LessonStatuses: {
    completed: "Проведено",
    planned: "Запланировано",
    earlyCanceledByCustomer: "Заблаговременная отмена слушателями",
    earlyCanceledBySchool: "Заблаговременная отмена преподавателем",
    lateCanceledByCustomer: "Поздняя отмена слушателями",
    lateCanceledBySchool: "Поздняя отмена преподавателем",
    notPerformedToPay: "К оплате",
  },
};

export default ru;
