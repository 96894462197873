import { Locale } from "date-fns";
import { ru, enUS } from "date-fns/locale";
import i18n from "i18next";

type FormatRelativeLocaleType = {
  lastWeek: string;
  yesterday: string;
  today: string;
  tomorrow: string;
  nextWeek: string;
  other: string;
};

const formatRelativeLocaleEn = {
  lastWeek: "dd.MM.yyyy",
  yesterday: "dd.MM.yyyy",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "dd.MM.yyyy",
  other: "dd.MM.yyyy",
};

type IToken = keyof FormatRelativeLocaleType;

export const localeEn: Locale = {
  ...enUS,
  formatRelative: (token: IToken) => formatRelativeLocaleEn[token],
};

const formatRelativeLocaleRu = {
  lastWeek: "dd.MM.yyyy",
  yesterday: "dd.MM.yyyy",
  today: "'Сегодня'",
  tomorrow: "'Завтра'",
  nextWeek: "dd.MM.yyyy",
  other: "dd.MM.yyyy",
};

export const localeRu: Locale = {
  ...ru,
  formatRelative: (token: IToken) => formatRelativeLocaleRu[token],
};

export function getCurrentLocale(): Locale {
  if (i18n.language === "ru" || i18n.language === "tt") return localeRu;
  return localeEn;
}
