import { useCallback, useMemo, MouseEventHandler, useEffect } from "react";

import {
  Stack,
  Box,
  Typography,
  useTheme,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Cell } from "react-table";

import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";
import { Course } from "@packages/store/models/Course/CourseModel";

import { AvatarPerson } from "components/AvatarPerson";
import { IconContainer } from "components/CardClass/styledComponents";
import { Teachers } from "components/CardModule";
import { AvatarsContainer } from "components/CardModule/styledComponents";
import { Icon } from "components/Icon";
import { Progress } from "components/Progress";
import { Table } from "components/Table";
import { TooltipCustom } from "components/Tooltip";
import { TooltipTeachers } from "components/TooltipTeachers";
import { ROUTES } from "router/constants";
import { wordDeclensionEndings } from "utils/basic";

import {
  Container,
  ContainerHour,
  ProgressContainer,
} from "./styledComponents";

export const MainModule = observer((): JSX.Element | null => {
  const { id } = useParams();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    auth,
    course: courseStore,
    learningGroup: learningGroupsStore,
    loading,
    api,
  } = useStores();

  const { getCourse } = courseStore;

  const { items: learningGroups } = learningGroupsStore;

  const { user } = auth;

  const { isHR = false } = user ?? {};

  const { fetch: fetchCourses, loading: loadingCourses } = useCancelableFetch();
  const { fetch: fetchPassings } = useCancelableFetch();

  useEffect(() => {
    fetchCourses((token) => api.getCourses(token));
  }, [fetchCourses, api]);

  useEffect(() => {
    if (isHR || !id) {
      return;
    }
    fetchPassings((token) => api.getPassings(id, token));
  }, [api, fetchPassings, id, isHR]);

  const course = getCourse(id);

  const units = course?.getUnits({ all: true }) ?? [];

  const isLoading = !course || loadingCourses || loading;

  const moduleTableDate = units
    .sort((a, b) => Number(a?.order || Infinity) - Number(b?.order || Infinity))
    .map((unit) => {
      return {
        unit: {
          id: unit.id,
          name: `${t("Module:UnitExercises")} ${unit.order}. ${
            unit.nameWithoutUnit
          }`,
          exercisesCount: unit.exercisesCount,
          disabled: course?.isForTesting && !unit.available,
        },
        testResults: unit.testResults,
        totalProgress: unit.totalProgress,
        result: unit.resultProgress,
        classesPlanned: unit.classesPlanned,
      };
    });

  const getTeachers = useCallback((): Teachers[] => {
    let teachers: Teachers[] = [];
    const groups = learningGroups;

    groups?.forEach((group) => {
      const classGroup = group?.classGroups?.find(
        (item) => String(item?.course?.id) === course?.id
      );

      if (classGroup) {
        teachers = group?.uniqueTeachers?.map((teacher) => ({
          name: `${teacher.fullName}`,
          post: "Teacher",
        }));
      }
    });

    return teachers;
  }, [course, learningGroups]);

  const getSubtitle = (module: Course) => {
    if (module?.passing?.testingKind === "entrance") {
      return t("Module:CurrentLevel", {
        level: module?.passing?.cefrLevel,
      });
    }

    return `${module?.knowledgeLevel?.nameTranslated || ""} • ${
      module?.knowledgeLevel?.code || ""
    } ${
      module?.finishDate
        ? t("CardModule:ends", {
            date: format(module?.finishDate, "dd MMMM yyyy"),
          })
        : ""
    }`;
  };

  const renderAvatarNames = useCallback(() => {
    if (getTeachers().length > 2) {
      return getTeachers()
        .slice(0, 3)
        .map((teacher, i) =>
          i < 2 ? (
            <AvatarPerson key={teacher.name} name={teacher.name} />
          ) : (
            <AvatarPerson
              key={teacher.name}
              name={`+ ${getTeachers().length - 2}`}
            />
          )
        );
    }
    return getTeachers().map((teacher) => (
      <AvatarPerson key={teacher.name} name={teacher.name} />
    ));
  }, [getTeachers]);

  const showUnitId = useCallback(
    (
        unitId?: string | undefined
      ): MouseEventHandler<HTMLSpanElement> | undefined =>
      () => {
        const selectedUnit = moduleTableDate?.find(
          ({ unit }) => unit.id === unitId
        );
        if (selectedUnit?.unit) {
          return navigate(
            `${ROUTES.MY_COURSES}/${id}${ROUTES.MODAL_UNIT}/${selectedUnit.unit.id}`
          );
        }
        return undefined;
      },
    [id, navigate, moduleTableDate]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("Module:UnitExercises"),
        accessor: "unit",
        Cell: ({ value }: Cell) => {
          return (
            <Box sx={{ display: "flex" }}>
              <IconContainer
                iconColor={theme.palette.info.light}
                theme={theme}
                variant="success"
              >
                <Icon type="elementary" width={22} height={22} />
              </IconContainer>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "space-around",
                }}
              >
                <Typography
                  variant="h4"
                  color={value.disabled ? "custom.grey.main" : "primary.dark"}
                  onClick={!value.disabled ? showUnitId(value.id) : undefined}
                  sx={{
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textDecoration: value.disabled ? "none" : "underline",
                    marginLeft: theme.spacing(3),
                  }}
                >
                  {value.name}
                </Typography>

                {value.exercisesCount && (
                  <Typography
                    variant="h4"
                    color="gray.main"
                    sx={{
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginLeft: theme.spacing(3),
                    }}
                  >
                    {`${value.exercisesCount} ${wordDeclensionEndings(
                      "exercise",
                      value.exercisesCount
                    )}`}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        },
      },
      {
        Header: t("Module:TotalProgress"),
        accessor: "totalProgress",
        maxWidth: 71,
        minWidth: 71,
        width: 71,
        Cell: ({ value }: Cell) => {
          return (
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              {value >= 0 ? (
                <Progress type="primary" value={value} />
              ) : (
                <CircularProgress size={20} />
              )}
            </Box>
          );
        },
      },
      {
        Header: t("Module:TestResults"),
        accessor: "testResults",
        maxWidth: 71,
        minWidth: 71,
        width: 71,
        Cell: ({ value }: Cell) => {
          return (
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              {value >= 0 ? (
                <Progress
                  type="primary"
                  colorSteps="success.main"
                  value={value}
                />
              ) : (
                <CircularProgress size={20} />
              )}
            </Box>
          );
        },
      },
      {
        Header: t("Module:Results"),
        accessor: "result",
        maxWidth: 71,
        minWidth: 71,
        width: 71,
        Cell: ({ value }: Cell) => {
          return (
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              {value >= 0 ? (
                <Progress
                  type="primary"
                  colorSteps="success.main"
                  value={value}
                />
              ) : (
                <CircularProgress size={20} />
              )}
            </Box>
          );
        },
      },
      {
        Header: t("Module:ClassesPlanned"),
        accessor: "classesPlanned",
        Cell: ({ value }: Cell) => {
          return (
            <ContainerHour sx={{ background: theme.palette.primary.light }}>
              {value ?? "-"}
            </ContainerHour>
          );
        },
      },
    ],
    [t, theme, showUnitId]
  );

  const getAvatars = renderAvatarNames();

  return (
    <Container>
      {isLoading ? (
        <Typography> {t("Global:Loading")}</Typography>
      ) : (
        <>
          <Stack direction="row" sx={{ gap: "16px" }}>
            {!!getAvatars.length && (
              <Tooltip
                placement="bottom"
                title={<TooltipTeachers dataTeachers={getTeachers()} />}
              >
                <AvatarsContainer>{getAvatars}</AvatarsContainer>
              </Tooltip>
            )}

            <Box>
              <Stack>
                <Typography
                  variant="h1"
                  sx={{ color: theme.palette.custom.grey.dark }}
                >
                  {course?.nameTranslated}
                </Typography>
              </Stack>

              <Typography
                variant="h4"
                mt={theme.spacing(3)}
                sx={{ color: theme.palette.custom.grey.main }}
              >
                {getSubtitle(course)}
              </Typography>
            </Box>
          </Stack>

          <ProgressContainer>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                [theme.breakpoints.down(480)]: {
                  flexDirection: "column",
                },
              }}
            >
              <TooltipCustom
                type="default"
                tooltipText={t("Module:TotalProgressTooltip")}
                placement="bottom"
              >
                <Box width="13.8rem" sx={{ cursor: "pointer" }}>
                  <Typography variant="h4" mb="0.4rem">
                    {t("StudentPageModule:TotalProgress")}
                  </Typography>
                  <Progress type="primary" value={course?.totalProgress} />
                </Box>
              </TooltipCustom>

              <TooltipCustom
                type="default"
                tooltipText={t("Module:ResultsTooltip")}
                placement="bottom"
              >
                <Box
                  width="13.8rem"
                  sx={{
                    marginLeft: "1.5rem",
                    cursor: "pointer",
                    [theme.breakpoints.down("tablet")]: {
                      marginLeft: "0rem",
                    },
                  }}
                >
                  <Typography variant="h4" mb="0.4rem">
                    {t("StudentPageModule:Result")}
                  </Typography>
                  <Progress
                    type="primary"
                    colorSteps="success.main"
                    value={course?.resultProgress}
                  />
                </Box>
              </TooltipCustom>
            </Stack>
          </ProgressContainer>
          <Table columns={columns} data={moduleTableDate} count={8} />
          <Outlet />
        </>
      )}
    </Container>
  );
});
