import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";
import { TagModel } from "@packages/store/models/Tag/Tag";

import { Button } from "components/Button";
import { ContentContainer } from "components/ContentContainer";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useFetch } from "hooks/useFetch";

import { TagModal, TagModalFormValues, TaggingListTable } from "./components";

export const TaggingList = observer((): JSX.Element | null => {
  const { id } = useParams();

  const { t } = useTranslation();

  const {
    taggingList: taggingListStore,
    tag: tagStore,
    addError,
    api,
  } = useStores();

  const { fetch: fetchTaggingList } = useCancelableFetch();

  const taggingList = taggingListStore.getTaggingListById(id);

  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);

  const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
  const selectedTag = taggingList?.getTag(selectedTagId) ?? null;
  const mode = selectedTag ? "change" : "new";

  const { fetch: createTag, loading: createTagLoading } = useFetch(
    (data: any) => {
      return taggingList?.createTag(data) as any;
    },
    (tag: any) => {
      tagStore?.addItem(TagModel.create(tag));
    }
  );

  const { fetch: deleteTag, loading: deleteTagLoading } = useFetch(
    (tagId: string) => {
      return taggingList?.deleteTag(tagId) as any;
    },
    (tagId: string) => {
      tagStore?.removeTag(tagId);
    }
  );

  const handleTagChange = (tagId: string) => {
    setSelectedTagId(tagId);
    setIsOpen(true);
  };

  const handleTagDelete = async (tagId: string) => {
    setSelectedTagId(null);

    deleteTag(tagId);
  };

  const handleTagFormSubmit = async (data: TagModalFormValues) => {
    if (mode === "change") {
      if (!selectedTag) return false;

      const isTagUpdated = await selectedTag.updateTag(data);

      if (isTagUpdated) {
        return closeModal();
      }

      return addError("error");
    }

    if (mode === "new") {
      createTag(data);

      return closeModal();
    }

    return true;
  };

  const handleAddClick = () => {
    setSelectedTagId(null);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!id) return;

    fetchTaggingList(() => api.getTaggingList(id));
  }, [api, fetchTaggingList, id]);

  return (
    <>
      <ContentContainer>
        <PageBlock title={t("TaggingList:Title")}>
          <Box sx={{ mb: 4 }} />
          <TaggingListTable
            tags={taggingList?.tags ?? []}
            onTagChange={handleTagChange}
            onTagDelete={handleTagDelete}
          />
          <Box display="flex" justifyContent="flex-end">
            <Loading loading={createTagLoading} wrapperStyles={{ p: 0 }}>
              <Button variant="primary" onClick={handleAddClick}>
                {t("TaggingList:NewTag")}
              </Button>
            </Loading>
          </Box>
        </PageBlock>
      </ContentContainer>
      <TagModal
        title={
          mode === "change"
            ? t("TaggingList:ChangeTagModalTitle")
            : t("TaggingList:CreateTagModalTitle")
        }
        open={isOpen}
        onClose={closeModal}
        initialData={selectedTag}
        onSubmit={handleTagFormSubmit}
      />
    </>
  );
});
