import { memo } from "react";

import { Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ModalAttendanceFormValues,
  ModalAttendanceStudent,
} from "components/ModalAttendance";

import { StudentCheckbox } from "../StudentCheckbox";

interface StudentsProps {
  control: Control<ModalAttendanceFormValues>;
  students: ModalAttendanceStudent[];
  disabled?: boolean;
}

export const Students = memo(
  ({
    control,
    students,
    disabled = false,
  }: StudentsProps): JSX.Element | null => {
    const { field } = useController({
      control,
      name: "studentIds",
    });
    const { t } = useTranslation();

    const allStudentsSelected = field.value?.length === students.length;

    const handleCheckAll = () => {
      if (allStudentsSelected) {
        return field.onChange([]);
      }

      return field.onChange(students.map(({ value }) => value));
    };

    const handleCheck = (value: number) => {
      if (field.value?.includes(value)) {
        return field.onChange(
          field.value?.filter((selectedValue) => selectedValue !== value)
        );
      }

      return field.onChange([...field.value, value]);
    };

    return (
      <>
        <Typography
          sx={{ marginBottom: "1rem" }}
          variant="h2"
          color="custom.grey.main"
        >
          {t("ModalAttendance:students")}
        </Typography>
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox checked={allStudentsSelected} onChange={handleCheckAll} />
          }
          label={
            <Typography
              variant="h4"
              color={disabled ? "text.secondary" : "text.primary"}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {t("ModalAttendance:checkAll")}
            </Typography>
          }
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px 20px",
            marginBottom: "1.25rem",
          }}
        >
          {students.map((student) => (
            <StudentCheckbox
              key={student.value}
              student={student}
              checked={field.value?.includes(student.value)}
              disabled={disabled}
              onCheck={handleCheck}
            />
          ))}
        </Box>
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography
            sx={{ cursor: "pointer" }}
            variant="button"
            color="info.main"
          >
            {t("ModalAttendance:checkStatusNotify")}
          </Typography>
        </Box>
      </>
    );
  }
);
