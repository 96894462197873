import { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";

import { ItemsWithPagination } from "components/ItemsWithPagination";
import { ListModules } from "components/ListModules";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";

import {
  ScheduleContainer,
  WrapperMainStudents,
} from "./components/styledComponents";
import { LessonItem } from "../../../components/LessonItem";

export const MainStudents = observer((): JSX.Element => {
  const {
    course: courseStore,
    lesson: lessonStore,
    learningGroup: learningGroupStore,
    api,
    auth,
  } = useStores();

  const { t } = useTranslation();

  const {
    fetch: fetchGroups,
    loading: loadingGroups,
    error: groupsError,
  } = useCancelableFetch();

  const { activeCoursesWithoutGroup } = courseStore;

  const { activeGroups, activeCourses } = learningGroupStore;

  const itemsLessons = lessonStore.sortForMainPage?.map((lesson) => {
    return <LessonItem key={lesson.id} lesson={lesson} />;
  });

  useEffect(() => {
    fetchGroups((token) =>
      api.getLearningGroups(
        { includeLessons: true, studentIdToCutByExcludeDate: auth.user?.id },
        token
      )
    );
  }, [api, auth.user?.id, fetchGroups]);

  return (
    <WrapperMainStudents>
      <PageBlock title={t(`StartPageStudent:myClasses`)}>
        <Loading loading={loadingGroups} error={groupsError}>
          {itemsLessons.length > 0 && (
            <ScheduleContainer>
              <ItemsWithPagination items={itemsLessons} countPage={3} />
            </ScheduleContainer>
          )}
        </Loading>
      </PageBlock>

      <PageBlock title={t(`StartPageStudent:myModules`)}>
        <Loading loading={loadingGroups} error={groupsError}>
          <ListModules
            countPage={2}
            isBottom
            courses={[...activeCourses, ...activeCoursesWithoutGroup]}
            groups={activeGroups}
          />
        </Loading>
      </PageBlock>
    </WrapperMainStudents>
  );
});
