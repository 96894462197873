const tt = {
  Classes: {
    SampleByStatus: "Дәресләр статусы буенча (билгеле) чорга сайлап алу",
    OnlyLessonsWithoutGroup: "Төркемсез дәресләр генә",
    Title: "Дәресләр",
    Company: "Компания",
    Group: "Төркем",
    Status: "Статус",
    ClassIndex: "Дәрес",
    ClassDate: "Дәрес көне",
    Teacher: "Укытучы",
    None: "Берни дә сайланмаган",
    Process: "Эшкәртү",
    DateRangeError: "Эшкәртү өчен башлану һәм тәмамлану көннәрен сайлагыз.",
    NoDataError: "Эшкәртү өчен мәгълүмат юк.",
    StandardHelper:
      "Мәгълүматны күрсәтү өчен, ким дигәндә бер фильтр кулланыгыз.",
    ProcessHelper: "Мәгълүматны күрсәтү өчен көн сайлагыз.",
    Processing: "Мәгълүматлар эшкәртелә",
    Type: "Тип",
    Unit: "Бүлек",
    Duration: "Ак.сәгать",
  },
  ClassesDownload: {
    Download: "Бушатырга",
    Classes: "Дәресләр",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Төркем исемен языгыз",
    SelectGroup: "Төркем сайлау",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Укытучылар табылмаган",
    SelectTeacher: "Укытучы сайлау",
  },
  Loading: {
    Error: "Уңышсыз йөкләү, битне яңартып карагыз",
  },
  DateRangePicker: {
    StartDate: "Башлану көне",
    EndDate: "Тәмамлану көне",
  },
  DatePicker: {
    Today: "Бүген",
    Clear: "Чистарту",
  },
  LessonStatuses: {
    planned: "Планлаштырылган",
    completed: "Үткәрелде",
    studentLateCancellation: "Тыңлаучылар тарафыннан соң бетерү",
    earlyCanceledByCustomer: "Тыңлаучылар тарафыннан алдан ук бетерү",
    earlyCanceledBySchool: "ДС Алдан ук бетерү",
    lateCanceledByCustomer: "Тыңлаучылар тарафыннан соң бетерү",
    lateCanceledBySchool: "ДС соң бетерү",
    notPerformedToPay: "Түләүгә",
  },
};

export default tt;
