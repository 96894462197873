import { useEffect, useRef } from "react";

import { Notification as INotification } from "@packages/store/models/Notifications/NotificationModel";

import { useIsInViewport } from "hooks";

import { Notification } from "../Notification";

interface IntersectingNotificationProps {
  notification: INotification;
}

export function IntersectingNotification({
  notification,
}: IntersectingNotificationProps): JSX.Element | null {
  const ref = useRef<Element>(null);
  const isIntersecting = useIsInViewport(ref);

  useEffect(() => {
    if (isIntersecting) {
      notification.markAsRead();
    }
  }, [isIntersecting, notification]);

  return <Notification intersectingRef={ref} notification={notification} />;
}
