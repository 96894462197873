import { TextField, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";

import { DistributorFormContent } from "./DistributorForm.styled";

interface DateRangeType {
  dateFrom: Date;
  dateTo: Date;
}

export const DistributorForm = observer((): JSX.Element => {
  const { generateDistributorReport } = useStores();
  const { register, handleSubmit } = useForm<DateRangeType>({});
  const { t } = useTranslation();

  const onSubmit = async ({ dateFrom, dateTo }: DateRangeType) => {
    await generateDistributorReport({ dateFrom, dateTo });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DistributorFormContent>
        <TextField
          label={t("Distributor:DateFrom")}
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true, required: true }}
          {...register("dateFrom", { required: true })}
        />
        <TextField
          label={t("Distributor:DateTo")}
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true, required: true }}
          {...register("dateTo", { required: true })}
        />
        <Button type="submit" variant="primary">
          {t("Button:Send")}
        </Button>
      </DistributorFormContent>
    </form>
  );
});
