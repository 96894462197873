import { useEffect, useState } from "react";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";

import { Icon } from "components/Icon";
import { Loading } from "components/Loading";

import { ModalContainer, ModalBody } from "./styledComponents";

export interface MyWordsDictionaryProps {
  wordId?: string;
  onClose?: () => void;
}

export const MyWordsDictionary = observer(
  ({ wordId, onClose }: MyWordsDictionaryProps): JSX.Element => {
    const { t } = useTranslation();
    const { word: wordStore } = useStores();
    const [currentTab, setCurrentTab] = useState(0);

    const wordModel = wordStore.items.find((item) => item.id === wordId);

    const handleTabChange = (_event: React.SyntheticEvent, value: number) => {
      setCurrentTab(value);
    };

    const handleClose = () => {
      setCurrentTab(0);
      onClose?.();
    };

    const {
      words = [],
      dictionaryLoading = false,
      header = "",
    } = wordModel ?? {};

    useEffect(() => {
      wordModel?.loadDictionary(currentTab);
    }, [currentTab, wordModel]);

    const { translations = [] } = wordModel?.dictionaryWord(currentTab) ?? {};

    if (!wordModel) {
      return <></>;
    }

    return (
      <ModalContainer open onClose={handleClose}>
        <ModalBody>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "1rem",
              paddingRight: "1rem",
              paddingLeft: "1rem",
            }}
          >
            <Typography variant="h2">{header}</Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              onClick={handleClose}
            >
              <Icon stroke="gray" type="close" width={16} height={16} />
            </Box>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              textColor="primary"
              value={currentTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{
                ".MuiTab-textColorPrimary.Mui-selected": {
                  color: "#5783F1",
                },
                ".MuiTabs-indicator": {
                  bgcolor: "#5783F1",
                },
              }}
            >
              {words.map((label) => (
                <Tab label={label} />
              ))}
            </Tabs>
          </Box>
          <Box
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.5rem"
          >
            <Loading loading={dictionaryLoading}>
              <>
                {translations.map((translation, index) => (
                  <Typography key={translation}>
                    {index + 1}. {translation}
                  </Typography>
                ))}
                {translations.length === 0 && (
                  <Typography fontStyle="italic">
                    {t("MyWords:MissingTranslation")}
                  </Typography>
                )}
              </>
            </Loading>
          </Box>
        </ModalBody>
      </ModalContainer>
    );
  }
);
