import { createInstance } from "i18next";

import ar from "./ar";
import en from "./en";
import es from "./es";
import ru from "./ru";
import tt from "./tt";
import ur from "./ur";
import uz from "./uz";

const i18n = createInstance({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  nsSeparator: ":",
  resources: { ar, en, ru, uz, ur, tt, es },
});

i18n.init();

export default i18n;
