import { Tooltip } from "@mui/material";
import { format } from "date-fns";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { FormattedLesson, formatLesson } from "../../helpers";
import { Icon } from "../Icon";
import { LessonStatusesTeacher } from "../LessonStatusesTeacher";
import { Table } from "../Table";
import { TableCell } from "../TableCell";
import { TooltipCustom } from "../Tooltip";
import { TooltipTeachers } from "../TooltipTeachers";

interface LessonTableProps {
  lessons: Lesson[];
  count: number;
  page: number;
  onChangePage?: (page: number, isLastPage: boolean) => void;

  showGroupReport: (groupId: string) => void;
  showAttendanceReport: (lessonId: string) => void;
}

export const LessonTable = observer(
  ({
    lessons,
    count,
    page,
    onChangePage,
    showGroupReport: topShowGroupReport,
    showAttendanceReport,
  }: LessonTableProps): JSX.Element | null => {
    const { t } = useTranslation();

    const showGroupReport = (groupId: string | undefined) => () => {
      if (!groupId) return;

      topShowGroupReport(groupId);
    };

    const getLessonIndexValue = (
      technicalCancellation: boolean | null,
      index: number
    ) => {
      if (technicalCancellation) {
        return <Icon type="checkmark" sizeSquareIcon={18} />;
      }

      return index || "-";
    };

    const columns = [
      {
        Header: t("Classes:Group"),
        accessor: "group",
        Cell: ({
          value,
          row,
        }: Cell<FormattedLesson, FormattedLesson["group"]>) => (
          <TooltipCustom
            placement="bottom"
            type="default"
            tooltipText={`${t("Classes:Company")} ${
              row.original.customerCompany.name ?? "-"
            }`}
          >
            <TableCell
              variant={value.id ? "buttonUnderlined" : undefined}
              onClick={showGroupReport(value.id)}
              minHeight="3.45rem" // Чтобы не дергалась таблица при переключении страниц
            >
              {value.name ?? "-"}
            </TableCell>
          </TooltipCustom>
        ),
      },
      {
        Header: t("Classes:ClassDate"),
        accessor: "startDate",
        Cell: ({ value }: Cell<FormattedLesson, Date>) => (
          <TableCell>{format(value, "dd.MM.yyyy")}</TableCell>
        ),
      },
      {
        Header: t("Classes:ClassIndex"),
        accessor: "index",
        Cell: ({ row, value }: Cell<FormattedLesson, number>) => (
          <TableCell>
            {getLessonIndexValue(row.original.technicalCancellation, value)}
          </TableCell>
        ),
      },
      {
        Header: t("Classes:Teacher"),
        accessor: "teacher",
        Cell: ({ value }: Cell<FormattedLesson, string | undefined>) => {
          if (value) {
            return (
              <Tooltip
                placement="bottom"
                title={
                  <TooltipTeachers
                    dataTeachers={[
                      {
                        name: value,
                        post: `${t("ModalGroupReport:Teacher")}`,
                      },
                    ]}
                  />
                }
              >
                <TableCell>{value}</TableCell>
              </Tooltip>
            );
          }

          return <TableCell>-</TableCell>;
        },
      },
      {
        Header: t("Classes:Duration"),
        accessor: "duration",
        Cell: ({ value }: Cell<FormattedLesson, string>) => {
          return <TableCell>{value}</TableCell>;
        },
      },
      {
        Header: t("Classes:Type"),
        accessor: "typeShort",
        Cell: ({ value }: Cell<FormattedLesson, string>) => {
          return <TableCell>{value}</TableCell>;
        },
      },
      {
        Header: t("Classes:Unit"),
        accessor: "actualUnitId",
        Cell: ({ value }: Cell<FormattedLesson, number | null>) => (
          <TableCell>{value ?? "-"}</TableCell>
        ),
      },
      {
        Header: t("Classes:Status"),
        accessor: "status",
        Cell: ({ value, row }: Cell<FormattedLesson, string>) => (
          <TableCell onClick={() => showAttendanceReport(row.original.id)}>
            <LessonStatusesTeacher type={value} />
          </TableCell>
        ),
      },
    ];

    const formattedLessons = computed(() => lessons.map(formatLesson)).get();

    return (
      <Table
        columns={columns}
        data={formattedLessons}
        count={count}
        containerType="blue"
        emptyTableType="classes"
        page={page}
        onChangePage={onChangePage}
      />
    );
  }
);
