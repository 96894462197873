const ar = {
  Exercise: {
    Units: "الوحدات",
    Blocks: "الكتل",
    Exercises: "التمارين",
    Unit: "وحدة",
    Block: "كتلة",
    Exercise: "تمرين",
    Script: "نص الفيديو",
    HideScript: "إخفاء النص",
    ShowScript: "عرض النص",
    LanguageSamples: "أمثلة لغوية",
    Extras: "إضافات",
    ToolReload: "إعادة تحميل",
    ToolTranslate: "ترجمة",
    ToolCheck: "تحقق",
    ToolNext: "التالي",
    IsCheckedWarning: "تم التحقق من المهمة بالفعل",
    NotFilledWarning: "لم تجب على جميع الأسئلة",
    Translate: "ترجمة",
    Vocabulary: "المفردات",
    NoTranslation: "لا توجد ترجمة",
    AddToDictionary: "إضافة إلى القاموس",
    SelectedTranslationsWarning: "يجب اختيار 1-3 ترجمات",
    TranslateWordFailed: "فشل في ترجمة الكلمة",
    AddWordsFailed: "فشل في إضافة الكلمات",
    AddWordsSuccess: "تمت إضافة الكلمات بنجاح",
    AddedBefore: "تمت إضافته سابقًا",
    EnterText: "اكتب هنا",
    Characters: "أحرف",
    Words: "كلمات",
    TextSize: "حجم النص الخاص بك:",
    WordsAtMinimum: "الحد الأدنى للكلمات",
    LearnWords: "تعلم الكلمات",
    Ok: "حسنًا",
    Expand: "توسيع",
    Collapse: "طي",
    Close: "إغلاق",
    ResizeTooltip: "مدد للقراءة",
    ResetChangesTooltip: "إعادة تعيين جميع التغييرات",
    NoChange: "لا توجد تغييرات",
    CorrectAnswer: "الإجابة الصحيحة",
    DevTooltip:
      "هذا النوع من التمارين قيد التطوير، للانتقال إلى الواجهة القديمة",
    FollowLink: "اتبع هذا الرابط",
    CreativeExercisePassed: "تم إرسال عملك إلى معلمك",
    CreativeExerciseVerified: "تم فحص عملك. انقر",
    CreativeExerciseVerifiedLink: "هنا",
    DialogExerciseConfirmAnswer: "تأكيد",
    DialogExerciseResetAnswer: "إعادة التسجيل",
    DialogExerciseSelectAnswer: "هذا",
    DialogExerciseHelperLegend: "كيفية تنفيذ التمرين",
    DialogExerciseFinishHelperLegend: "تهانينا!",
    DialogExerciseFirstHelper:
      "اختر إجابتك ذهنيًا من قائمة الرسائل أدناه، انقر على الميكروفون وتحدث بها.",
    DialogExerciseSecondHelper:
      "رائع، النظام تعرف على كلامك! يرجى تأكيد الإجابة أو إعادة التسجيل.",
    DialogExerciseThirdHelper:
      "لقد نفدت محاولات الإدخال الصوتي، اختر أحد خيارات الإجابة يدويًا.",
    DialogExerciseFinishHelper:
      "لقد أكملت التمرين. يمكنك الاستماع إلى الحوار بالكامل أو الانتقال إلى التمرين التالي بالضغط على زر التحقق",
    DialogExerciseRecognitionError:
      "للأسف، لم يتمكن النظام من التعرف على إجابتك، يرجى المحاولة مرة أخرى.",
    AudioRecorderText: "انقر للتسجيل ({{count}}) ثانية",
    RadioLineHelperTextLeft: "لم يتم إعطاء جميع الإجابات الصحيحة. ابحث عن",
    RadioLineHelperTextRight: "إجابات صحيحة أخرى.",
    NextDisabledWarning: "تحقق من التمرين",
    BrokenError: "هذا التمرين قيد التطوير، يرجى إكماله في الواجهة القديمة.",
    BrokenErrorLink: "رابط",
  },
  ResultsModals: {
    UnitTitle: "نتائج الوحدة {{name}}",
    BlockTitle: "نتائج الكتلة {{name}}",
    Passed: "تم الاجتياز: {{count}}",
    Wrong: "خطأ: {{count}}",
    Skipped: "تم تخطيه: {{count}}",
    Blocks: "الكتل",
    Block: "كتلة {{order}}: {{name}}",
    Exercises: "التمارين",
    Exercise: "تمرين {{order}}: {{name}}",
    Repeat: "إعادة",
    Continue: "استمرار",
    DontShowAgain:
      "لا تظهر هذه النافذة مرة أخرى (يمكنك إعادة تشغيلها من إعدادات ملفك الشخصي)",
    TestUnitTitle: "تهانينا! لقد أكملت مرحلة الاختبار {{order}}",
    TestUnitResultTitle: "نتيجتك هي",
    TestUnit: "وحدة {{order}}. {{name}}",
    TestUnitTotalProgress: "التقدم العام",
    TestUnitResults: "النتائج",
    TestUnitNext: "ابدأ المرحلة التالية",
    TestUnitScoredPoints_zero: "{{count}}/{{total}} نقاط",
    TestUnitScoredPoints_one: "{{count}}/{{total}} نقطة",
    TestUnitScoredPoints_other: "{{count}}/{{total}} نقاط",

    TestCourseTitle: "تهانينا! لقد انتهيت من الاختبار",
    TestCourseRecomendedLevel: "البرنامج الموصى به للمستوى هو -",
    TestCourseTotalProgress: "التقدم العام",
    TestCourseResultProgress: "النتائج",
    TestCourseShowResults: "عرض النتائج",
    TestCourseShowHourse: "عدد الساعات الأكاديمية",
    TestCourseHint:
      "التركيز على تطوير جميع المهارات اللغوية (التحدث، الاستماع، القراءة والكتابة)",
    TestCourseA1Long:
      "أفهم المعنى الرئيسي للمحادثة. أستطيع وصف أصدقائي وعائلتي",
    TestCourseA2Long: "أفهم الجمل الفردية وأستطيع التحدث عن الموضوعات اليومية",
    TestCourseB1Long:
      "أستطيع التواصل في معظم المواقف باستخدام جميع الأزمنة الفعلية",
    TestCourseB2Long: "أستطيع الدفاع عن وجهة نظري، التفاوض والمراسلة",
    TestCourseC1Long: "أقرأ الأدب بطلاقة وأستطيع تقديم عروض",
    TestCourseC2Long: "أتواصل في أي موضوع بمستوى قريب من متحدث أصلي",
    TestCourseA1Short: "مبتدئ، ابتدائي",
    TestCourseA2Short: "ما قبل المتوسط",
    TestCourseB1Short: "متوسط",
    TestCourseB2Short: "فوق المتوسط",
    TestCourseC1Short: "متقدم",
    TestCourseC2Short: "فوق المتقدم",
  },
  TestingCourseResult: {
    ModalTitle: "نتائج الاختبار",
    Confirm: "انتقل إلى النتائج",
    ModalBodyEntrance:
      "عزيزي {{fullName}}، يوصى لك بدورة مستوى CEFR: {{cefrLevel}}.",
    ModalBodyFinal:
      "عزيزي {{fullName}}، لقد أكملت الاختبار بنتيجة {{courseMark}} من {{maxMark}}.\nنسبتك هي {{percentage}}%.",
    ModalBodyDefault: "عزيزي {{fullName}}، لقد أكملت الاختبار بنجاح.",
  },
};

export default ar;
