import { useCallback } from "react";

import { observer } from "mobx-react-lite";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { Classes as PackageClasses, Filters } from "@packages/classes";
import { buildSearchParams } from "@packages/shared/utils/searchParams";

import { ROUTES } from "router/constants";

export const Classes = observer((): JSX.Element => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const showGroupReport = useCallback(
    (groupId: string) => {
      return navigate(`${ROUTES.CLASSES}${ROUTES.MODAL_GROUP}/${groupId}`);
    },
    [navigate]
  );

  const showAttendanceReport = useCallback(
    (lessonId: string) => {
      return navigate(`${ROUTES.CLASSES}${ROUTES.ATTENDANCE}/${lessonId}`);
    },
    [navigate]
  );

  const syncSearchParams = useCallback(
    (data: Filters) => {
      if (pathname !== ROUTES.CLASSES) return;

      const params = buildSearchParams(data);

      if (params.toString() !== searchParams.toString()) {
        setSearchParams(params);
      }
    },
    [pathname, searchParams, setSearchParams]
  );

  return (
    <>
      <PackageClasses
        showGroupReport={showGroupReport}
        showAttendanceReport={showAttendanceReport}
        searchParams={searchParams}
        syncSearchParams={syncSearchParams}
      />

      <Outlet />
    </>
  );
});
