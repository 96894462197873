import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as ReactLink } from "react-router-dom";

import { FooterProps } from "components/Footer";
import {
  FooterContainer,
  ContentContainer,
  FooterLogoContainer,
  FooterLinksContainer,
  LinksContainer,
  SocialLinksContainer,
} from "components/Footer/styledComponents";
import { Icon } from "components/Icon";
import { Logo } from "components/Logo";

export const FooterDesktop = ({ routerLinks }: FooterProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <ContentContainer>
        <FooterLogoContainer>
          <ReactLink to="/">
            <Logo />
          </ReactLink>
        </FooterLogoContainer>
        <FooterLinksContainer>
          <LinksContainer>
            {/* <Link
              href={routerLinks.PricticeOnAi.url}
              variant="smallTextUnderlined"
              target="_blank"
            >
              {t(`Footer:Practice on AI`)}
            </Link> */}
            <Link
              href={routerLinks.About.url}
              variant="smallTextUnderlined"
              target="_blank"
            >
              {t(`Footer:About`)}
            </Link>
          </LinksContainer>
          <LinksContainer>
            <Link
              href={routerLinks.Terms.url}
              variant="smallTextUnderlined"
              target="_blank"
            >
              {t(`Footer:Terms & Conditions`)}
            </Link>
            <Link
              href={routerLinks.Privacy.url}
              variant="smallTextUnderlined"
              target="_blank"
            >
              {t(`Footer:Privacy Policy`)}
            </Link>
          </LinksContainer>
          <SocialLinksContainer>
            <Link
              href={routerLinks.VKontakte.url}
              variant="smallTextUnderlined"
              target="_blank"
            >
              <Icon type={routerLinks.VKontakte.icon} width={20} height={20} />
              <span>{t(`Footer:VKontakte`)}</span>
            </Link>
          </SocialLinksContainer>
        </FooterLinksContainer>
      </ContentContainer>
    </FooterContainer>
  );
};
