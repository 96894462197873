import { Box } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Course } from "@packages/store/models/Course/CourseModel";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

import { CardModule } from "components/CardModule";
import { ItemsWithPagination } from "components/ItemsWithPagination";
import { dataTeachers } from "constants/constants";
import { addEmptyModule } from "utils/basic";

import { MyModulesContainer, ModuleContainer } from "./styledComponents";

export interface TeacherListModulesProps {
  countPage: number;
  courses: Course[];
  groups?: LearningGroup[];
  continueUrl?: string;
}

export const TeacherListModules = observer(
  ({ countPage, courses }: TeacherListModulesProps): JSX.Element => {
    const { t } = useTranslation();

    const getSubtitle = (module: Course) => {
      if (module?.passing?.testingKind === "entrance") {
        return t("Module:CurrentLevel", {
          level: module?.passing?.cefrLevel,
        });
      }

      return `${module?.knowledgeLevel?.nameTranslated || ""} • ${
        module?.knowledgeLevel?.code || ""
      } ${
        module?.finishDate
          ? t("CardModule:ends", {
              date: format(module?.finishDate, "dd MMMM yyyy"),
            })
          : ""
      }`;
    };

    const itemsCardModule = addEmptyModule(courses)?.map((module) => (
      <ModuleContainer>
        <CardModule
          key={module.id}
          module={{
            title: module.name || "",
            subTitle: getSubtitle(module),
            url: `/my-courses/${module.id}`,
            id: module.id,
          }}
          isProgress={false}
          dueDate={module.finishDate}
          dataTeachers={dataTeachers}
          picture={module.picture}
          isHide={Boolean(module.isHide)}
        />
      </ModuleContainer>
    ));

    return (
      <Box mb="2.5rem">
        <ItemsWithPagination
          items={itemsCardModule}
          countPage={countPage}
          ContainerItems={MyModulesContainer}
        />
      </Box>
    );
  }
);
