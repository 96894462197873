import { Box, useTheme } from "@mui/material";

import { Notification as INotification } from "@packages/store/models/Notifications/NotificationModel";

import { IntersectingNotification } from "../IntersectingNotification";
import { Notification } from "../Notification";

interface NotificationsListProps {
  notifications: INotification[];
  intersecting?: boolean;
}

export function NotificationsList({
  notifications,
  intersecting = false,
}: NotificationsListProps): JSX.Element | null {
  const theme = useTheme();

  return (
    <Box
      sx={{
        "& > div:not(:last-child)": {
          borderBottom: `1px solid ${theme.palette.custom.grey["emenu-tile-active"]}`,
        },
      }}
    >
      {notifications?.map((notification) =>
        intersecting ? (
          <IntersectingNotification
            key={notification!.id}
            notification={notification}
          />
        ) : (
          <Notification key={notification!.id} notification={notification} />
        )
      )}
    </Box>
  );
}
