const es = {
  Groups: {
    title: "Grupos",
  },
  GroupList: {
    group: "Grupo",
    dates: "Período de clases",
    report: "Informes de clases",
    statistics: "Estadísticas de la plataforma",
    progress: "Progreso de la plataforma",
    lessonsCount: "Clases realizadas",
    total: "Total de horas",
    finished: "Horas realizadas",
    left: "Horas restantes",
    period: "Período",
    teachers: "Profesores",
    attendance: "Asistencia",
  },
  ModalGroupReport: {
    Download: "Descargar",
    Teacher: "Profesor",
    Course: "Curso",
    Schedule: "Horario",
    Hours: "horas",
    TotalHours: "Total de horas",
    CompletedHours: "Horas realizadas",
    LeftHours: "Horas restantes",
    Compensation: "Compensación",
    Students: "Estudiantes",
    Duration: "Duración",
  },
  GroupFilter: {
    ActiveGroups: "Grupos activos",
    ActiveGroupsNo: "No",
    ActiveGroupsYes: "Sí",
    SelectLevel: "Seleccionar nivel",
    SearchStudent: "Buscar estudiante",
    SearchGroup: "Buscar grupo",
    SelectModule: "Seleccionar módulo",
    AllModules: "Todos los módulos",
    AllCompanies: "Todas las empresas",
    SelectCompany: "Seleccionar empresa",
    StartDate: "Fecha de inicio",
    EndDate: "Fecha de finalización",
    WithLessonsWithoutTeachers: "Sin profesor",
    WithLessonsOnlyTechnicalCancellationBefore: "Cancelaciones técnicas",
  },
  GroupDownload: {
    Download: "Exportar",
    Groups: "Grupos",
    WithoutPrice: "Grupos sin precio",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Ingrese el nombre del grupo",
    SelectGroup: "Seleccionar grupo",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "No tienes módulos de estudio asignados.",
    SecondTextDefault: "Contacta a tu coordinador de formación.",
    FirstTextDistributor: "No hay datos para mostrar.",
    SecondTextDistributor: "Intenta ingresar algún dato.",
    FirstTextClasses: "No hay datos para mostrar.",
    SecondTextClasses: "Intenta cambiar los filtros o contacta con soporte.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "No se encontraron profesores",
    SelectTeacher: "Seleccionar profesor",
  },
  ShortWeekDay: {
    "0": "DO",
    "1": "LU",
    "2": "MA",
    "3": "MI",
    "4": "JU",
    "5": "VI",
    "6": "SA",
  },
  LessonStatuses: {
    completed: "Realizado",
    planned: "Planificado",
    earlyCanceledByCustomer:
      "Cancelación anticipada por parte de los estudiantes",
    earlyCanceledBySchool: "Cancelación anticipada por parte del profesor",
    lateCanceledByCustomer: "Cancelación tardía por parte de los estudiantes",
    lateCanceledBySchool: "Cancelación tardía por parte del profesor",
    notPerformedToPay: "A pagar",
  },
};

export default es;
