const uz = {
  AttendancePivotTable: {
    Student: "Talaba",
    Visited: "Tashrif buyurdi, %",
    Hours: "Soatlar soni",
    Month: "Oy",
    Lesson: "Dars",
    Date: "Sana",
    Duration: "Davomiylik",
    Teacher: "O'qituvchi",
    Days: "Kunlar",
    Time: "Vaqt",
    Course: "Kurs {{course, string}}",
    GroupSchedule: "{{group, string}} guruhidagi dars jadvali",
    Total: "Dastur {{hours, number}} soat",
    CompletedPeriod: "Davrda bajarilgan {{hours, number}} soat",
    LeftPeriod: "Davr oxirida qoldi {{hours, number}} soat",
    NotPerformedToPayPeriod: "Ortiqcha bekor qilishlar {{hours, number}} soat",
    TotalInTable: "Dastur",
    CompletedInTable: "Davrda bajarilgan",
    NotPerformedToPayHours: "Ortiqcha bekor qilishlar: ",
    LeftInTable: "Davr oxirida qoldi",
    MinHours: "ac.h.",
  },
  ReportsAttendance: {
    Title: "Davomat",
    SelectGroup: "Guruhni tanlang",
    AllGroups: "Barcha guruhlar",
    EmptyGroupAndCompany:
      "Hisobotni ko'rish uchun kompaniya yoki guruhni tanlang",
    Download: "Yuklab olish",
    AllCompanies: "Barcha kompaniyalar",
    SelectCompany: "Kompaniyani tanlang",
    EmptyLessons:
      "Hozirda tanlangan filtrlarga mos keladigan darslar mavjud emas, shuning uchun davomat bo'yicha hisobot tuzish imkoni yo'q",
    OnlyTeachersLessons: "Faqat mening darslarim",
  },
  AttendancePivotReportDownload: {
    Sheet: "Pivot hisobot",
  },
  DatePicker: {
    Today: "Bugun",
    Clear: "Tozalash",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Guruh nomini kiriting",
    SelectGroup: "Guruhni tanlang",
  },
  Loading: {
    Error: "Yuklashda xato, sahifani yangilashga harakat qiling",
  },
  ModalGroupReport: {
    Teacher: "O'qituvchi",
    Course: "Kurs",
    Schedule: "Jadval",
    Duration: "Davomiylik",
  },
  GroupFilter: {
    SearchStudent: "Talabani qidiring",
    StartDate: "Boshlanish sanasi",
    EndDate: "Tugash sanasi",
  },
  MonthName: {
    "1": "Yanvar",
    "2": "Fevral",
    "3": "Mart",
    "4": "Aprel",
    "5": "May",
    "6": "Iyun",
    "7": "Iyul",
    "8": "Avgust",
    "9": "Sentabr",
    "10": "Oktabr",
    "11": "Noyabr",
    "12": "Dekabr",
  },
  ShortWeekDay: {
    "0": "Yak.",
    "1": "Dush.",
    "2": "Sesh.",
    "3": "Chor.",
    "4": "Paysh.",
    "5": "Jum.",
    "6": "Shan.",
  },
  Progress: {
    totalProgress: "Umumiy progress",
    completed: "bajarildi",
    yourProgress: "Sizning progressingiz:",
  },
};

export default uz;
