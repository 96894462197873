import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const CourseLinkModel = types.model({
  id: types.identifier,
  name: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
});

type CourseLinkType = Instance<typeof CourseLinkModel>;
export type CourseLink = CourseLinkType;
type CourseLinkSnapshotType = SnapshotOut<typeof CourseLinkModel>;
export type CourseLinkSnapshot = CourseLinkSnapshotType;
export const createCourseLinkDefaultModel = () =>
  types.optional(CourseLinkModel, {} as CourseLinkSnapshot);
