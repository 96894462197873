import { Box, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useStores } from "@packages/store/models";

import { Button } from "components/Button";
import { Intro } from "pages/Login/components";
import {
  Container,
  FormWrapper,
  FormContainer,
} from "pages/Login/styledComponents";
import { ROUTES } from "router/constants";

export const EmailNotConfirmed = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const { auth } = useStores();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClickBack = () => {
    auth.logout();
    navigate(ROUTES.SIGN_UP_PAGE);
  };

  return (
    <Container>
      <Intro />
      <FormWrapper>
        <FormContainer sx={{ textAlign: "center" }}>
          {t("EmailNotConfirmed:Title", { email: auth.user?.email, lng: "ru" })}
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="text"
              sx={{
                color: theme.palette.info.main,
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={handleClickBack}
            >
              {t("EmailNotConfirmed:Back", { lng: "ru" })}
            </Button>
          </Box>
        </FormContainer>
      </FormWrapper>
    </Container>
  );
});
